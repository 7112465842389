import {
    createAiosPath,
    isWait,
    isFail,
    is,
} from 'aios';
import { type AppNode, type AppNodeControl, doLoadCancel, doReload, doRemove, doRemoveCancel, doRemoveConfirm, doRemoveConfirmCancel, doSubmit } from 'app';
import { AppDataForm, AppDataList, AppContentTree } from 'views';

export function appNodeLoadAccessUi(this: AppNode): void {
    if (is(this.data?.created)) {
        appNodeLoadAccessUiSave.call(this);
        return;
    }
    switch (this.path.action) {
        case 'create':
        case 'update':
            appNodeLoadAccessUiSave.call(this);
            break;
        case 'delete':
            appNodeLoadAccessUiRemove.call(this);
            break;
        default:
            appNodeLoadAccessUiDefault.call(this);
            break;
    }
}

function appNodeLoadAccessUiDefault(this: AppNode): void {
    if (isFail(this.result.status)) {
        this.interface.showControl({
            commands: [
                {
                    name: 'reload',
                    onChange: doReload,
                },
            ],
        });
        return;
    }
    if (isWait(this.result.status)) {
        this.interface.showControl({
            commands: [
                {
                    path: this.path.parent,
                    name: 'cancel',
                    blink: true,
                    onChange: doLoadCancel,
                },
            ],
        });
        return;
    }
    const control: AppNodeControl = {};
    if (!is(this.path.modeId)) {
        control.form = AppDataList;
        control.commands = [
            {
                name: 'create',
                path: createAiosPath({
                    path: this.path.path,
                    mode: 'access',
                    modeId: 'new',
                }).full,
            },
            {
                name: 'close',
                path: this.parent?.path?.path,
            },
        ];
        this.interface.showControl(control);
        return;
    }
    if (this.path.modeId === 'new') {
        appNodeLoadAccessUiSave.call(this);
        return;
    }
    control.form = AppDataForm
    control.commands = [
        {
            name: 'delete',
            path: `${this.path.full as string}&action=delete`,
        },
    ];
    this.interface.showControl(control);
}

function appNodeLoadAccessUiSave(this: AppNode): void {
    if (isFail(this.result.status)) {
        this.interface.showControl({
            form: AppDataForm,
            commands: [
                {
                    name: 'ok',
                    onChange: doReload,
                },
            ],
        });
        return;
    }
    if (isWait(this.result.status)) {
        this.interface.showControl({
            form: AppDataForm,
            commands: [
                {
                    path: this.path.parent,
                    name: 'cancel',
                    blink: true,
                    onChange: doLoadCancel,
                },
            ],
        });
        return;
    }
    const control: AppNodeControl = {
        form: AppDataForm,
        commands: [
            {
                name: 'save',
                onChange: doSubmit,
            },
            // {
            //   name: 'load',
            //   onChange: doReload,
            // },
        ],
    };
    // if (this.path.action === 'create') {
    //     control.commands?.push({
    //         name: 'cancel',
    //         path: this.path.parent,
    //         onChange: doAccessCreateCancel,
    //     });
    // } else {
    //     control.commands?.push({
    //         name: 'cancel',
    //         path: this.path.path,
    //     });
    // }
    this.interface.showControl(control);
    this.interface.switch({ chat: true });
}

function appNodeLoadAccessUiRemove(this: AppNode): void {
    if (isFail(this.result.status)) {
        this.interface.showControl({
            form: AppContentTree,
            commands: [
                {
                    name: 'retry',
                    icon: 'reload',
                    onChange: doReload,
                },
            ],
        });
        return;
    }
    doRemove.call(this);
    if (this.result.progress !== 0.5) {
        this.interface.showControl({
            form: AppContentTree,
            commands: [
                {
                    name: 'cancel',
                    path: this.path.path,
                    color: 'red',
                    blink: true,
                    onChange: doRemoveCancel,
                },
            ],
        });
        return;
    }
    this.interface.showControl({
        form: AppContentTree,
        commands: [
            {
                name: 'decline',
                path: this.path.path,
                onChange: doRemoveConfirmCancel,
            },
            {
                name: 'confirm',
                onChange: doRemoveConfirm,
            },
        ],
    });
}