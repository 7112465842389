import {
  AiosStatus,
  createAiosResult,
  delay,
  getId,
  is,
  isFail,
  isWait,
} from 'aios';
import { type AppNode } from '../../AppNode';
import { AppState } from 'signals/AppState/AppState';
import { appNodeLoadStage } from '../load/appNodeLoadStage';
import { appNodeRemoveNext } from './appNodeRemoveNext';

export function doRemove(this: AppNode): void {
  if (isFail(this.result.status)) {
    return;
  }
  if (isWait(this.result.status)) {
    return;
  }
  this.result = createAiosResult({
    id: getId(),
    title: `prparing to delete '${this.path.path as string}'`,
    status: AiosStatus.Processing,
    progress: 0.1,
  });
  void delay(async () => {
    await doRemovePrep.call(this);
  });
}

async function doRemovePrep(this: AppNode): Promise<void> {
  const remove = this.parent;
  if (!is(remove)) {
    return;
  }
  await appNodeLoadStage.call(remove);
  if (isWait(remove.result.status)) {
    if (!is(this.result.progress) || this.result.progress === 0.4) {
      this.result.progress = 0.2;
    } else {
      this.result.progress = this.result.progress + 0.1;
    }
    void delay(async () => {
      await doRemovePrep.call(this);
    });
    AppState.current.refresh();
    return;
  }
  this.result = createAiosResult({
    id: getId(),
    title: `confirming delete '${this.path.path as string}'`,
    status: AiosStatus.Processing,
    progress: 0.5,
  });
  AppState.current.refresh();
}

export async function doRemoveCancel(this: AppNode): Promise<void> {
  this.result.setFail({ status: AiosStatus.FailCancel });
  if (is(this.client)) {
    this.client.cancel();
  }
}

export async function doRemoveConfirm(this: AppNode): Promise<void> {
  this.result = createAiosResult({
    id: this.result.id,
    title: `deleting nodes '${this.path.full as string}'`,
    status: AiosStatus.Processing,
    progress: 0.5,
  });
  void delay(async () => {
    await appNodeRemoveNext.call(this);
  });
  AppState.current.refresh();
}

export async function doRemoveConfirmCancel(this: AppNode): Promise<void> {
  this.result.setFail({ status: AiosStatus.FailCancel });
  if (is(this.client)) {
    this.client.cancel();
    // todo: chech otherplace
  } else {
    if (is(this.parent)) {
      await this.parent.remove(this.path);
      this.parent.interface.showProcessing();
    }
  }
}
