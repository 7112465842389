import { is, isWait } from 'aios';
import { GridCell, GridCellIcon, GridCellText, GridRow } from 'components';
import { type ClassOptions } from 'signals';
import { type AppControlType, type AppControlProps } from './AppControlType';

export const AppContentProcessing: AppControlType = ({ node, show, hide }: AppControlProps) => {
  if (!is(node)) {
    return (<div>AppContentResult no node</div>);
  }
  const { result } = node;
  if (!is(result)) {
    return (<div>AppContentResult no result</div>);
  }
  const spin = isWait(result.status) ? true : undefined;
  const options: ClassOptions = {
    show: is(show) ? true : undefined,
    hide: is(hide) ? true : undefined,
    blink: spin
  };
  let icon;
  let status;
  switch (result.act) {
    case 'chat':
      icon = 'aios';
      break;
    case 'run':
      icon = 'settings';
      break;
    default:
      status = result?.status;
      break;
  }
  return (
    <>
      <GridRow>
        <GridCell height={5} options={{ ...options, borderLeft: true, borderTop: true, borderBottom: true }}>
          <GridCellIcon height={5} options={{ spin }} icon={icon} status={status} />
        </GridCell>
        <GridCellText height={5} options={{ ...options, yMid: true, borderTop: true, borderBottom: true }}>{result?.title}</GridCellText>
        <GridCell height={5} options={{ ...options, borderRight: true, borderTop: true, borderBottom: true }}>&nbsp;</GridCell>
      </GridRow>
      <GridRow>
        <GridCell spanx={3} space />
      </GridRow>
    </>
  );
};


