import { appSignal, useApp, useRoute } from 'signals';
import { Foot, Grid, GridCellButton } from 'components';
import { createAiosPath, is, set } from 'aios';

export const AdminFoot = (): JSX.Element => {
  const { root } = useApp();
  const { path, goTo } = useRoute();
  if (path.path === '' || path.path === '/') {
    return (<div>AppFoot no path</div>);
  }
  const node = root.find(path);
  if (!is(node)) {
    return (<div>AppFoot no node</div>);
  }
  const buttons = [];
  const commands = node.interface.control?.commands;
  if (is(commands)) {
    for (let i = 0; i < commands.length; i++) {
      const command = commands[i];
      buttons.push(
        <GridCellButton
          key={`app-foot-button-${command.name as string}`}
          name={command.name}
          icon={set(command.icon, command.name)}
          path={createAiosPath({ path: command.path })}
          onClick={async () => {
            if (is(command.onChange)) {
              await command.onChange.call(node);
            }
            if (is(command.path)) {
              const goToPath = createAiosPath({ full: command.path });
              goTo(goToPath);
            }
          }}
          onUpload={command.onUpload}
          downloadUrl={command.downloadUrl}
          downloadName={command.downloadName}
          options={{
            color: command.color,
            blink: command.blink,
            disabled: command.disabled,
            selected: command.selected,
            xMid: true
          }}
        />
      );
    }
  }
  if (is(appSignal.value.indexMenu)) {
    return (
      <Foot>
        <div style={{ width: '25vw' }}>
          &nbsp;
        </div>
        <div style={{ width: '75vw' }}>
          <Grid cols={buttons.length} rows={1} width={25} height={10} center>
            {buttons}
          </Grid>
        </div>
      </Foot>
    );
  }
  return (
    <Foot>
      <div style={{ width: '100vw' }}>
        <Grid cols={buttons.length} rows={1} width={100} height={10} center>
          {buttons}
        </Grid>
      </div>
    </Foot>
  );
};