import { type AppNode } from '../../AppNode';
import { is, createAiosResult, AiosStatus, isWait, isFail, getId } from 'aios';
import { appNodeLoad } from './appNodeLoad';

export async function appNodeLoadStage(this: AppNode): Promise<void> {
  // todo: parallel
  // if (is(this.data?.aloaded)) {
  //   this.result = createAiosResult({
  //     id: getId(),
  //     title: `staged data '${this.path.path as string}'`,
  //     status: AiosStatus.Ok,
  //   });
  //   return;
  // }
  if (!isWait(this.result.status) || !is(this.result.progress) || this.result.progress < 0.1) {
    this.result = createAiosResult({
      id: getId(),
      title: `staging data '${this.path.full as string}'`,
      status: AiosStatus.Processing,
      progress: 0.1,
    });
    return;
  }
  await appNodeLoad.call(this);
  const nodes = this.nodes;
  if (is(nodes)) {
    let loaded = true;
    for (const node of nodes) {
      // if (is(node.path.mode) || is(node.path.action)) {
      if (is(node.path.action)) {
        continue;
      }
      await appNodeLoadStage.call(node);
      if (isFail(node.result.status)) {
        this.result.setFail();
        return;
      }
      if (isWait(node.result.status)) {
        loaded = false;
      }
    }
    if (!is(loaded)) {
      this.result.status = AiosStatus.Processing;
      this.result.title = `${this.result.title}.`;
      if (!is(this.result.progress) || this.result.progress === 0.9) {
        this.result.progress = 0.2;
      } else {
        this.result.progress = this.result.progress + 0.1;
      }
      return;
    }
  }
  this.result.setOk();
  const data = this.data;
  if (is(data)) {
    data.aloaded = true;
    this.data = data;
  }
}
