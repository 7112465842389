import { useEffect } from 'react';
import { useApp, useAuth, useRoute } from 'signals';
import { createAiosPath, is } from 'aios';
import { AppState } from 'signals/AppState/AppState';
import { Page } from 'components';
import { AppHead } from './AppHead';
import { AppBody } from './AppBody';
import { AppFoot } from './AppFoot';
import { doLoad, getNewN } from 'app';
import { Admin } from 'views';
import './AppPage.css';

export const AppPage = (): JSX.Element => {
  const { root } = useApp();
  const { account } = useAuth();
  const { path, goTo } = useRoute();
  const accountId = localStorage.getItem('aios_account_id');
  const authPath = localStorage.getItem('aios_auth_path');
  AppState.current.full = path.full;
  AppState.current.goTo = goTo;
  useEffect(() => {
    if (!is(path) || !is(path.path) || !is(path.full)) {
      goTo(createAiosPath({ path: 'aios' }));
      return;
    }
    if (path.path === '' || path.path === '/') {
      goTo(createAiosPath({ path: 'aios' }));
      return;
    }
    if ((getNewN(path.path) > -1 || path.modeId === 'new') && path.action !== 'create') {
      const editPath = createAiosPath({ full: path.full, action: 'create' });
      goTo(editPath);
      return;
    }
    if (is(accountId)) {
      if ((accountId !== account?.id)) {
        if (path.path !== 'aios' || path.action !== 'auth') {
          localStorage.setItem('aios_auth_path', path.full);
          goTo(createAiosPath({ path: 'aios', action: 'auth' }));
          return;
        }
      } else {
        if (is(authPath)) {
          localStorage.removeItem('aios_auth_path');
          goTo(createAiosPath({ full: authPath }));
          return;
        }
      }
    }
    const load = async (): Promise<void> => {
      await doLoad.call(root, path);
    }
    void load();
  }, [path, goTo, account, accountId]);
  if (path.path === 'admin') {
    return (
      <Page>
        <AppHead />
        <Admin />
      </Page>
    );
  }
  return (
    <Page>
      <AppHead />
      <AppBody />
      <AppFoot />
    </Page>
  );
};
