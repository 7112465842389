import { type AiosPath, is } from 'aios';
import { getClass, useRoute } from 'signals';
import { type Grid2CellIconProps } from './Grid2CellIcon';
import { Grid2Cell } from './Grid2Cell';

export interface Grid2CellLinkProps extends Grid2CellIconProps {
  path?: AiosPath;
}

export const Grid2CellLink = (props: Grid2CellLinkProps): JSX.Element => {
  const { goTo } = useRoute();
  const onClick = (): void => {
    if (is(props.path)) {
      goTo(props.path)
    }
  }
  return (
    <Grid2Cell {...props}>
      <div className={getClass('grid2-cell-button')} onClick={onClick}>
        <div className={getClass('grid2-cell-text')}>
          <span data-testid={props.test}>{props.name}</span>
        </div>
      </div>
    </Grid2Cell>
  );
}
