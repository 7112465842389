import { type ReactNode, useState } from 'react';
import { AppNodeType, type AppNode } from 'app';
import { type AiosAsset, AiosFileType, AiosType, getId, is } from 'aios';
import { Grid2CellIcon, Grid2Row } from 'components';
import { Grid2CellLink } from 'components/Grid2/Grid2CellLink';
import { Grid2CellButton } from 'components/Grid2/Grid2CellButton';

export interface AppSideRowsProps {
  node: AppNode;
}

const getIcon = (node: AppNode): string => {
  const { path, version, mode } = node.path;
  if (path === 'aios') {
    return 'aios';
  }
  if (is(mode)) {
    return 'access';
  }
  if (is(version)) {
    // if (version === 'all'){
    //   return 'version';
    // }
    return 'asset';
  }
  switch (node.data?.type) {
    case AiosType.Account:
      return 'account';
    case AiosType.Asset:
      {
        const asset = node.data?.item as AiosAsset;
        if (is(asset)) {
          switch (asset.file?.type) {
            case AiosFileType.Folder:
              return 'folder';
            case AiosFileType.Data:
              return 'data';
            case AiosFileType.Text:
              return 'text';
            case AiosFileType.Code:
              return 'code';
            case AiosFileType.Json:
              return 'json';
            case AiosFileType.Image:
              return 'image';
            case AiosFileType.Audio:
              return 'audio';
            case AiosFileType.Model:
              return 'model';
            case AiosFileType.Video:
              return 'video';
            case AiosFileType.Markdown:
              return 'markdown';
          }
        }
        return 'asset';
      }
  }
  return 'help';
}

const getName = (node: AppNode): string => {
  const { path, name, version, mode, modeId } = node.path;
  if (is(mode)) {
    if (!is(modeId)) {
      return 'access';
    }
    return modeId;
  }
  if (is(version)) {
    if (version === 'all') {
      return 'version';
    }
    return version;
  }
  if (is(name) && name.includes('new')) {
    return name;
  }
  switch (node.data?.type) {
    case AiosType.Asset:
      {
        const asset = node.data?.item as AiosAsset;
        const assetName = asset?.name;
        if (is(assetName)) {
          return assetName;
        }
      }
  }
  if (is(name)) {
    return name;
  }
  if (is(path)) {
    return path;
  }

  return '?';
}

export const AppSideRows = ({ node }: AppSideRowsProps): JSX.Element => {
  const [show, setShow] = useState('show');
  const onClick = async (): Promise<void> => {
    if (show === 'hide') {
      setShow('show')
    } else {
      setShow('hide')
    }
  }
  const items: ReactNode[] = [];
  const addItem = (item: AppNode): void => {
    const { type, path, nodes } = item;
    const { action } = path;
    const icon = getIcon(node);
    let test = icon === 'account' ? 'account-button' : path.assetPath;
    if (is(test)) {
      if (test.includes('/')) {
        test = test.replaceAll('/', '-');
      }
      if (test.includes('.')) {
        test = test.replaceAll('.', '-');
      }
    }
    if (type !== AppNodeType.Root && !is(action)) {
      items.push(
        <Grid2Row key={getId()}>
          <Grid2CellIcon icon={icon}/>
          {!is(nodes) ? (
            <Grid2CellLink span={9} test={test} name={getName(node)} path={path} />
          ) : (
            <>
              <Grid2CellLink span={8} test={test} name={getName(node)} path={path} />
              <Grid2CellButton icon={show} onClick={onClick} />
            </>
          )}
        </Grid2Row>
      );
    }
    if (is(nodes) && show === 'show') {
      for (const child of nodes) {
        const action = child.path?.action;
        if (is(action)) {
          continue;
        }
        items.push(
          <AppSideRows
            key={getId()}
            node={child}
          />
        );

      }
    }
  }
  addItem(node);
  return (
    <>
      {items}
    </>
  );
};
