import type { AiosData } from './AiosData';
import type { AiosDataCommandType } from './AiosDataCommandType';
import { AiosType } from '../AiosType';
import { set } from '../functions';

export interface AiosDataCommand<T extends AiosData> {
  aiosType: AiosType;
  commandType?: AiosDataCommandType;
  item?: T;
}

export function createAiosDataCommand<T extends AiosData>(
  options?: AiosDataCommand<T>,
): AiosDataCommand<T> {
  const action: AiosDataCommand<T> = {
    aiosType: set(options?.aiosType, AiosType.None),
    commandType: set(options?.commandType, undefined),
    item: set(options?.item, undefined),
  };
  return action;
}

export default AiosDataCommand;
