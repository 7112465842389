import { type AppDataProps, type AppDataType } from 'views/AppData';

export const AppContentAsset: AppDataType = ({ node }: AppDataProps) => {
    return <></>;
    //   const action = node.path?.action;
    //   if (action !== 'update' && action !== 'create') {
    //     return (<AppContentAssetView node={node} />);
    //   }
    //   return (
    //     <>
    //       <AppContentAssetHead node={node} />
    //       {/* <AppContentAssetBody node={node} />
    //       <GridRow>
    //         <GridCell spanx={3} space />
    //       </GridRow> */}
    //     </>
    //   );
};

export const AppContentTree: AppDataType = ({ node }: AppDataProps) => {
    return <></>;
    //   const parentNode = node.parent as AppNode;
    //   return (
    //     <>
    //       <AppContentTreeNode key='top-res' node={node} />
    //       <AppContentTreeNode key='tree-res' node={parentNode} expanded />
    //     </>    
    //   );
    // <>
    //   <GridRow>
    //     <GridCellIcon height={5} icon='form' />
    //     <GridCell>
    //       <GridEx size={5} cols0={2} cols1={1} cols2={1}>
    //         <AppContentTreeNode node={node} />
    //       </GridEx>
    //     </GridCell>
    //     <GridCellButton height={5} icon='help' />
    //   </GridRow>
    //   {/* <AppContentTreeNode node={parentNode} expanded /> */}
    // </>
};