import { type AiosStatus, is, isFail, isOk, isWait, set } from 'aios';
import { getClass } from 'signals';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArticleIcon from '@mui/icons-material/Article';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import BoltIcon from '@mui/icons-material/Bolt';
import BugReportIcon from '@mui/icons-material/BugReport';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DifferenceIcon from '@mui/icons-material/Difference';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FolderIcon from '@mui/icons-material/Folder';
import HelpIcon from '@mui/icons-material/Help';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ImageIcon from '@mui/icons-material/Image';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import PowerIcon from '@mui/icons-material/Power';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ReplayIcon from '@mui/icons-material/Replay';
import RemoveIcon from '@mui/icons-material/Remove';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import TransformIcon from '@mui/icons-material/Transform';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';

import { Grid2Cell, type Grid2CellProps } from './Grid2Cell';

export interface Grid2CellIconProps extends Grid2CellProps {
  name?: string;
  icon?: string;
  status?: AiosStatus;
}

export function getGrid2IconProps(props: Grid2CellIconProps): { icon: JSX.Element, props: Grid2CellIconProps } {
  const copy = { ...props };
  let icon = <></>;
  let spin = false;
  let color = '';
  if (is(copy.icon)) {
    copy.name = copy.icon;
  }
  if (is(copy.status)) {
    if (isOk(copy.status)) {
      copy.name = 'ok';
    } else {
      if (isWait(copy.status)) {
        copy.name = 'wait';
      } else {
        if (isFail(copy.status)) {
          copy.name = 'fail';
        }
      }
    }
  }
  const lower = is(copy.name) ? copy.name.toLowerCase() : '';
  switch (lower) {
    case 'access':
      icon = <LockOutlinedIcon />;
      break;
    case 'account':
      icon = <AccountTreeIcon />;
      break;
    case 'action':
      icon = <QueryStatsIcon />;
      break;
    case 'aios':
      icon = <AcUnitIcon />;
      break;
    case 'asset':
      icon = <ContentCopyIcon />;
      break;
    case 'back':
      icon = <ArrowBackIcon />;
      break;
    case 'cancel':
      icon = <CloseIcon />;
      color = 'red';
      break;
    case 'chat':
      icon = <VoiceChatIcon />;
      break;
    case 'close':
      icon = <CloseIcon />;
      break;
    case 'code':
      icon = <CodeIcon />;
      break;
    case 'confirm':
      icon = <ThumbUpIcon />;
      color = 'green';
      spin = true;
      break;
    case 'connect':
      icon = <PowerIcon />;
      break;
    case 'content':
      icon = <MenuBookOutlinedIcon />;
      break;
    case 'create':
      icon = <AddIcon />;
      break;
    case 'dark':
      icon = <DarkModeIcon />;
      break;
    case 'data':
      icon = <DatasetOutlinedIcon />;
      break;
    case 'decline':
      icon = <ThumbDownIcon />;
      color = 'red';
      break;
    case 'debug':
      icon = <BugReportIcon />;
      break;
    case 'delete':
      icon = <DeleteForeverIcon />;
      break;
    case 'desktop':
      icon = <ViewQuiltIcon />;
      break;
    case 'diff':
      icon = <DifferenceIcon />;
      break;
    case 'download':
      icon = <CloudDownloadIcon />;
      break;
    case 'fail':
      icon = <CloseIcon />;
      color = 'red';
      break;
    case 'file':
      icon = <FileOpenIcon />;
      break;
    case 'folder':
      icon = <FolderIcon />;
      break;
    case 'form':
      icon = <EditNoteIcon />;
      break;
    case 'generate':
      icon = <TransformIcon />;
      break;
    case 'help':
      icon = <HelpIcon />;
      break;
    case 'hide':
      icon = <ChevronRightIcon />;
      break;
    case 'image':
      icon = <ImageIcon />;
      break;
    case 'install':
      icon = <InstallMobileIcon />;
      break;
    case 'invoke':
      icon = <BoltIcon />;
      break;
    case 'json':
      icon = <DataObjectIcon />;
      break;
    case 'less':
      icon = <ExpandLessIcon />;
      break;
    case 'left':
      icon = <KeyboardArrowLeftIcon />;
      break;
    case 'lessex':
      icon = <UnfoldLessIcon />;
      break;
    case 'light':
      icon = <LightModeIcon />;
      break;
    case 'load':
      icon = <ReplayIcon />;
      break;
    case 'markdown':
      icon = <NewspaperIcon />;
      break;
    case 'menu':
      icon = <MenuIcon />;
      break;
    case 'model':
      icon = <ViewInArIcon />;
      break;
    case 'more':
      icon = <ExpandMoreIcon />;
      break;
    case 'moreex':
      icon = <UnfoldMoreIcon />;
      break;
    case 'movie':
      icon = <VideoFileIcon />;
      break;
    case 'narrow':
      icon = <SmartphoneIcon />;
      break;
    case 'ok':
      icon = <DoneIcon />;
      color = 'green'
      break;
    case 'profile':
      icon = <AccountBoxIcon />;
      break;
    case 'reload':
      icon = <ReplayIcon />;
      color = 'yellow'
      break;
    case 'reset':
      icon = <ReplayIcon />;
      break;
    case 'remove':
      icon = <RemoveIcon />;
      break;
    case 'right':
      icon = <KeyboardArrowRightIcon />;
      break;
    case 'run':
      icon = <BoltIcon />;
      break;
    case 'save':
      icon = <DoneIcon />;
      color = 'green'
      break;
    case 'savenx':
      icon = <DoneIcon />;
      break;
    case 'send':
      icon = <SendIcon />;
      break;
    case 'show':
      icon = <ExpandMoreIcon />;
      break;
    case 'sound':
      icon = <AudioFileIcon />;
      break;
    case 'settings':
      icon = <SettingsIcon />;
      break;
    case 'text':
      icon = <ArticleIcon />;
      break;
    case 'tree':
      icon = <AccountTreeIcon />;
      break;
    case 'undo':
      icon = <ReplayIcon />;
      color = 'blue';
      break;
    case 'uninstall':
      icon = <MobileOffIcon />;
      break;
    case 'unknown':
      icon = <PsychologyAltIcon />;
      break;
    case 'update':
      icon = <EditIcon />;
      break;
    case 'upload':
      icon = <CloudUploadIcon />;
      break;
    case 'user':
      icon = <PersonIcon />;
      break;
    case 'version':
      icon = <CopyAllIcon />;
      break;
    case 'wait':
      icon = <HourglassEmptyIcon />;
      color = 'yellow';
      spin = true;
      break;
  }
  return {
    icon,
    props: {
      ...props,
      spin: set(props?.spin, spin),
      color: set(props?.color, color)
    }
  };
}

export const Grid2CellIcon = (iprops: Grid2CellIconProps): JSX.Element => {
  const { icon, props } = getGrid2IconProps(iprops);
  const className = getClass('grid2-cell-icon');
  return (
    <Grid2Cell {...props}>
      <div className={className}>
        {icon}
      </div>
    </Grid2Cell>
  );
}