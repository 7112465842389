import { AppNodeType, createAppNode } from 'app';
import { type AppSettings } from './AppSettings';
import { AiosStatus, createAiosPath, createAiosResult, type AiosPath } from 'aios';

export class AppState {
  static current: AppState;

  constructor() {
    AppState.current = this;
  }

  root = createAppNode({
    id: 'app-root',
    path: createAiosPath({ path: '/' }),
    type: AppNodeType.Root,
    name: 'root',
    result: createAiosResult({ status: AiosStatus.Ok }),
    nodes: [
      createAppNode({
        id: 'app-aios-node',
        path: createAiosPath({ path: 'aios' }),
        type: AppNodeType.Aios,
        name: 'aios',
        result: createAiosResult(),    
      }),
    ],
  });

  settings: AppSettings = {
    menu: false,
    dark: false,
    thin: false,
    wapp: false,
  };

  setSettings = (values: Partial<AppSettings>): void => {
    const settings = { ...this.settings, ...values };
    this.refresh({ settings });
  };

  full?: string;

  goTo = (path: AiosPath): void => {
    console.log('AppState goto not implemented');
    console.log(path);
  }

  refresh = (app?: Partial<AppState>): void => {
    console.log('AppState refresh not implemented');
    console.log(app);
  };
}
