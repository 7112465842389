import { useState, useRef } from 'react';
import { AiosStatus, createAiosResult, delay, getId, is, isFail } from 'aios';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { type ErrorSchema } from '@rjsf/utils';
import { type IChangeEvent } from '@rjsf/core';
import { useApp, useRoute } from 'signals';
import { AppState } from 'signals/AppState/AppState';
import { type AppDataType, type AppDataProps } from './AppDataType';
import { appNodeLoadAssetUi } from 'app/node/functions/asset/appNodeLoadAssetUi';
import { GridCell, GridRow } from 'components';
import { getAppNodeData } from 'app';
import { getFormSchema } from './getFormSchema';

export const AppDataForm: AppDataType = ({ node, disabled }: AppDataProps) => {
  const { root } = useApp();
  const { path, goTo } = useRoute();
  const ref = useRef<HTMLButtonElement>(null);
  const { dataNode, item } = getAppNodeData(node);
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({});
  if (!is(dataNode)) {
    return <div>AppDataForm no node</div>;
  }
  if (!is(item)) {
    return <div>no item</div>;
  }
  const onChange = ({ formData }: IChangeEvent): void => {
    dataNode.update(formData);
    AppState.current.refresh();
  };
  const onSubmit = ({ formData }: IChangeEvent): void => {
    dataNode.update(formData);
    node.result = createAiosResult({
      id: getId(),
      title: `submiting save '${node.path.path as string}'`,
      status: AiosStatus.Processing,
    });
    node.interface.showProcessing(node.result);
    void delay(async (): Promise<void> => {
      const saveNode = await root.save(path);
      if (!is(saveNode)) {
        return;
      }
      const saveResult = saveNode?.result;
      if (!is(saveResult)) {
        return;
      }
      if (isFail(saveResult.status)) {
        const fields = saveResult?.fields;
        if (is(fields)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fieldErrors: ErrorSchema<any> = {};
          const entries = Object.entries(fields);
          entries.forEach((fieldError) => {
            const [fkey, fmessage] = fieldError;
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            fieldErrors[fkey] = {
              __errors: [fmessage],
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as ErrorSchema<any>;
          });
          setExtraErrors(fieldErrors);
        } else {
          saveNode.interface.showHistory({ result: saveNode.result });
        }
        appNodeLoadAssetUi.call(saveNode);
        AppState.current.refresh();
        return;
      }
      const returnPath = saveNode?.path;
      if (is(returnPath)) {
        goTo(returnPath);
      }
    });
    AppState.current.refresh();
  };
  if (is(node.interface.control)) {
    node.interface.showControl({
      ...node.interface.control,
      doReset: () => {
        dataNode.restore();
        AppState.current.refresh();
      },
      doSubmit: async () => {
        const current = ref?.current;
        if (is(current)) {
          current.click();
        }
      }
    });
  }
  const schema = getFormSchema(path);
  const style = { display: '' };
  return (
    <GridRow>
      <GridCell>&nbsp;</GridCell>
      <GridCell>
        <div className='grid-form' style={style}>
          <Form
            schema={schema}
            formData={item}
            validator={validator}
            onChange={onChange}
            onSubmit={onSubmit}
            extraErrors={extraErrors}
            disabled={disabled}
          >
            <button ref={ref} type='submit' style={{ display: 'none' }} />
          </Form>
        </div>
      </GridCell>
      <GridCell>&nbsp;</GridCell>
    </GridRow>
  );
};