import {
  type AiosAction,
  type AiosAsset,
  type AiosDataResult,
  type AiosType,
  AiosDataCommandType,
  AiosStatus,
  createAiosResult,
  is,
  isWait,
  delay,
} from 'aios';
import { createAiosClientApp, getAppNodeData, getId } from 'app';
import { type AppNode } from '../../AppNode';
import { AppState } from 'signals/AppState/AppState';

export async function doInvoke(this: AppNode, input?: string): Promise<void> {
  this.result = createAiosResult({
    id: getId(),
    act: 'run',
    title: `${input ?? 'running'}`,
    status: AiosStatus.Processing,
    progress: 0.1,
  });
  this.interface.switch({ processing: this.result });
  void delay(async () => {
    await doInvokeData.call(this, input);
  });
  AppState.current.refresh();
}

export async function doInvokeCancel(this: AppNode): Promise<void> {
  if (this.result.progress === 0.1) {
    this.result.setFail({ status: AiosStatus.FailCancel });
    AppState.current.refresh();
  }
  if (is(this.client)) {
    this.client.cancel();
  }
}

async function doInvokeData(this: AppNode, input?: string): Promise<void> {
  if (!is(this.result)) {
    return;
  }
  if (!isWait(this.result.status)) {
    return;
  }
  this.result.progress = 0.2;
  const { path } = getAppNodeData(this);
  const code: AiosAction = {
    path,
    input,
  };
  this.interface.showHistory({
    id: getId(),
    text: `invoking ${this.path.path as string} (${code.input as string})`,
  });
  this.client = createAiosClientApp();
  this.process = await this.client.send({
    path: this.path,
    command: {
      aiosType: this.path.aiosType as AiosType,
      commandType: AiosDataCommandType.Run,
      item: code,
    },
  });
  if (is(this.process.output)) {
    this.result.override(this.process.output);
  }
  const result = this.process.output as AiosDataResult<AiosAsset>;
  this.interface.showHistory({
    id: getId(),
    from: 'aios',
    name: 'openai',    
    text: `${result?.item?.file?.text as string}`,
    result: this.result,
    process: this.process,
  });
  this.interface.switch({ chat: true });
  AppState.current.refresh();
}
