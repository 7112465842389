import { is } from 'aios';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';

export const formatStatus = (value?: string): string => {
  if (!is(value)) {
    return '';
  }
  return value.replace(/(.)([A-Z])/g, '$1 $2').toLowerCase();
};

export const formatDate = (date?: number): string => {
  let dateString = 'now';
  if (is(date)) {
    dateString = formatDistanceStrict(date, new Date(), { addSuffix: true });
    switch (dateString) {
      case '0 seconds ago':
        dateString = 'now';
        break;
    }
  }
  return dateString;
};
