import { is } from 'aios';
import { getClass } from 'signals';
import { Grid2Cell } from './Grid2Cell';
import { getGrid2IconProps, type Grid2CellIconProps } from './Grid2CellIcon';

export interface Grid2CellButtonProps extends Grid2CellIconProps {
  onClick?: () => Promise<void>;
}

export const Grid2CellButton = (props: Grid2CellButtonProps): JSX.Element => {
  const { icon } = getGrid2IconProps(props);
  const onClick = (): void => {
    if (is(props.onClick)) {
      void props.onClick();
    }
  }
  return (
    <Grid2Cell {...props}>
      <div className={getClass('grid2-cell-button')} onClick={onClick}>
        {is(props.icon) ? (
          <div style={{ width: is(props.name) ? '80%' : undefined }} className={getClass('grid2-cell-icon')}>
            {icon}
          </div>
        ) : (<></>)}
        {is(props.name) ? (
          <div className={getClass('grid-cell-text')}>
            <span data-testid={props.test}>{props.name}</span>
          </div>
        ) : (<></>)}
      </div>
    </Grid2Cell>
  );
}
