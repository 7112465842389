import {
  type AiosAccountSetting,
  AiosAccountSettingTheme,
} from './AiosAccountSetting';
import {
  add,
  type AiosData,
  type AiosDispatch,
  createAiosData,
  is,
  set,
} from '../AiosUtil';
import { type AiosAsset, createAiosAsset } from '../AiosAsset';
import { type AiosAccountFinance } from './AiosAccountFinance';

export interface AiosAccount extends AiosData {
  email?: string;
  assets?: AiosAsset[];
  finance?: AiosAccountFinance;
  setting?: AiosAccountSetting;
  dispatch?: AiosDispatch;
}

export function createAiosAccount(options?: Partial<AiosAccount>): AiosAccount {
  const base = createAiosData(options);
  const account: AiosAccount = {
    ...base,
    email: set(options?.email, undefined),
    finance: set(options?.finance, { allowance: 0, balance: 0, usage: 0 }),
    setting: set(options?.setting, { theme: AiosAccountSettingTheme.Dark }),
    dispatch: set(options?.dispatch, {}),
  };
  if (is(options)) {
    const { assets } = options;
    if (is(assets)) {
      for (let i = 0; i < assets.length; i++) {
        account.assets = add(account.assets, createAiosAsset(assets[i]));
      }
    }
  }
  return account;
}

export function updateAiosAccount(
  account: AiosAccount,
  options?: Partial<AiosAccount>,
): AiosAccount {
  if (is(options)) {
    if (account.path !== options?.path) {
      account.path = set(options?.path, '');
    }
    if (account.dispatch !== options?.dispatch) {
      account.dispatch = set(options?.dispatch, undefined);
    }
  }
  return account;
}
