import { is } from '../../';

export function filesInject(
  text: string,
  files: Record<string, string>,
): string {
  let resultText = text.trim();
  if (is(files.response)) {
    resultText = files.response;
  }
  for (const [filename, content] of Object.entries(files)) {
    if (filename !== 'response') {
      resultText += `\n// ${filename} start\n${content}\n// ${filename} finish`;
    }
  }
  return resultText.trim();
}
