import { Grid, GridCellButton, Head } from 'components';
import { createAiosPath, is, isId, set } from 'aios';
import { useAuth, appSignal } from 'signals';
import { doSignIn, doSignOut, getConfig } from 'app';
import { AppState } from 'signals/AppState/AppState';

export const AppHead = (): JSX.Element => {
  const { waiting, account } = useAuth();
  const display = getConfig('REACT_APP_AIOS_DNS');
  const accountString = !is(account) ? undefined : set(account.path, account.id);
  const indexToggle = async (): Promise<void> => {
    if (!is(appSignal.value.indexMenu)) {
      appSignal.value.indexMenu = true;
    } else {
      appSignal.value.indexMenu = false;
    }
    AppState.current.refresh();
  };
  const settingsToggle = async (): Promise<void> => {
    if (!is(appSignal.value.settingsMenu)) {
      appSignal.value.settingsMenu = true;
    } else {
      appSignal.value.settingsMenu = false;
    }
    AppState.current.refresh();
  };
  return (
    <Head>
      <Grid rows={1} cols={10} cols0={1} cols1={1} cols2={2} width={100} height={10}>
        <GridCellButton icon='menu' onClick={indexToggle} options={{ border: true }} />
        {!isId(accountString) ? (
          <GridCellButton name={`${display}/aios`} path={createAiosPath({ path: 'aios' })} options={{ border: true }} />
        ) : (
          <GridCellButton name={`${display}/${accountString}`} path={createAiosPath({ path: `/${accountString}` })} options={{ border: true }} />
        )}
        <GridCellButton icon='settings' onClick={settingsToggle} options={{ border: true }} />
        {!isId(accountString) ? (
          <GridCellButton icon='profile' onClick={doSignIn} options={{ xMid: true, disabled: waiting, border: true }} />
        ) : (
          <GridCellButton icon='profile' onClick={doSignOut} options={{ xMid: true, disabled: waiting, border: true }} />
        )}
      </Grid>
    </Head>
  );
};
