import { signal } from '@preact/signals';
import { is } from 'aios';

export interface AppSignal {
  pwa: boolean;
  light: boolean;
  large: boolean;
  narrow: boolean;
  indexMenu: boolean;
  settingsMenu: boolean;
}

export const appSignal = signal<AppSignal>({
  pwa: false,
  light: false,
  large: false,
  narrow: false,
  indexMenu: false,
  settingsMenu: false,
});

export interface ClassOptions {
  xMid?: boolean;
  xMax?: boolean;
  yMid?: boolean;
  yMax?: boolean;
  spin?: boolean;
  show?: boolean;
  hide?: boolean;
  blink?: boolean;
  color?: string;
  disabled?: boolean;
  selected?: boolean;
  border?: boolean;
  borderTop?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  margin?: boolean;
}

export const getClass = (className: string, options?: ClassOptions): string => {
  if (is(appSignal.value.light)) {
    className = `${className} light`;
  }
  if (is(appSignal.value.large)) {
    className = `${className} large`;
  }
  if (is(appSignal.value.narrow)) {
    className = `${className} narrow`;
  }
  if (is(options)) {
    if (is(options.xMid)) {
      className = `${className} x-mid`;
    }
    if (is(options.xMax)) {
      className = `${className} x-max`;
    }
    if (is(options.yMid)) {
      className = `${className} y-mid`;
    }
    if (is(options.yMax)) {
      className = `${className} y-max`;
    }
    if (is(options.spin)) {
      className = `${className} spin`;
    }    
    if (is(options.show)) {
      className = `${className} show`;
    }
    if (is(options.hide)) {
      className = `${className} hide`;
    }
    if (is(options.blink)) {
      className = `${className} blink`;
    }    
    if (is(options.color)) {
      className = `${className} ${options.color}`;
    }
    if (is(options.disabled)) {
      className = `${className} disabled`;
    }
    if (is(options.selected)) {
      className = `${className} selected`;
    }
    if (is(options.border)) {
      className = `${className} border`;
    }
    if (is(options.borderTop)) {
      className = `${className} border-top`;
    }
    if (is(options.borderLeft)) {
      className = `${className} border-left`;
    }
    if (is(options.borderRight)) {
      className = `${className} border-right`;
    }
    if (is(options.borderBottom)) {
      className = `${className} border-bottom`;
    }
  }
  return className;
};

export default appSignal;
