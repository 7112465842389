import { type AiosPath, AiosStatus, set, isWait, is, delay, createAiosResult } from 'aios';
import { type AppNode } from 'app';
import { AppState } from '../../../../signals/AppState/AppState';

interface AppPathStatus {
  load: string;
  full: string;
  status: AiosStatus;
  progress: number | undefined;
}

const appPathStatus: AppPathStatus = {
  load: '',
  full: '',
  status: AiosStatus.None,
  progress: undefined,
};

export async function doLoad(this: AppNode, path: AiosPath): Promise<void> {
  if (appPathStatus.load === path.full) {
    const find = this.find(path);
    if (is(find)) {
      if (appPathStatus.status === find.result.status && appPathStatus.progress === find.result.progress) {
        return;
      }
      // todo: when saving, need to set this
      // if (isWait(find.result.status)) {
      //   return;
      // }
    }
  }
  appPathStatus.load = path.full ?? '';
  const load = await this.load(path);
  if (
    appPathStatus.full === path.full &&
    appPathStatus.status === load?.result.status &&
    appPathStatus.progress === load?.result.progress
  ) {
    return;
  }
  appPathStatus.full = set(path.full, appPathStatus.full);
  appPathStatus.status = set(load?.result.status, appPathStatus.status);
  appPathStatus.progress = set(load?.result.progress, undefined);
  AppState.current.refresh();
  if (isWait(load?.result.status)) {
    void delay(async () => {
      appPathStatus.load = '';
      await doLoad.call(this, path);
    });
  }
}

export async function doLoadCancel(this: AppNode): Promise<void> {
  this.result.setFail({ status: AiosStatus.FailCancel });
  if (is(this.client)) {
    this.client.cancel();
  }
}

export async function doReload(this: AppNode): Promise<void> {
  this.interface.showHistory({ result: this.result });
  this.result = createAiosResult();
  AppState.current.goTo(this.path);
}
