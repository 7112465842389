import { type AppNode, AppNodeType, createAppNode } from 'app';
import { type AiosPath, is, isChildPath, getNextPath, createAiosPath, add, AiosType } from 'aios';
import { appNodeLoadAios } from '../aios/appNodeLoadAios';
import { appNodeLoadAccess } from '../access/appNodeLoadAccess';
import { appNodeLoadAccount } from '../account/appNodeLoadAccount';
import { appNodeLoadAction } from '../access/appNodeLoadAction';
import { appNodeLoadAsset } from '../asset/appNodeLoadAsset';

export async function appNodeLoad(this: AppNode, target?: AiosPath): Promise<AppNode | undefined> {
  if (!is(target)) {
    target = this.path;
  }
  const thisFull = this.path.full as string;
  const targetFull = target.full as string;
  if (thisFull !== targetFull) {
    return await appNodeLoadNodes.call(this, target);
  }
  return await appNodeLoadNode.call(this, target);
}

async function appNodeLoadNodes(this: AppNode, target: AiosPath): Promise<AppNode | undefined> {
  if (is(this.nodes)) {
    const targetFull = target.full as string;
    for (let i = 0; i < this.nodes.length; i++) {
      const child = this.nodes[i];
      const childFull = child.path?.full as string;
      if (isChildPath(child.path, target)) {
        await appNodeLoadNode.call(child, target);
        if (childFull === 'aios' || childFull === targetFull) {
          return child;
        }
        return await appNodeLoadNodes.call(child, target);
      }
    }
  }
  return await appNodeLoadInit.call(this, target);
}

async function appNodeLoadInit(this: AppNode, target: AiosPath): Promise<AppNode | undefined> {
  const targetFull = target.full as string;
  const nextPath = getNextPath(this.path, target);
  const path = createAiosPath({ full: nextPath });
  let next = this.find(path);
  if (!is(next)) {
    next = createAppNode({
      parent: this,
      path,
      name: path.name,
    });
    this.nodes = add(this.nodes, next);
  }
  if (path.full !== targetFull) {
    await appNodeLoadNode.call(next, target);
    return await appNodeLoadNodes.call(next, target);
  }
  return await appNodeLoadNode.call(next, target);
}

async function appNodeLoadNode(this: AppNode, target: AiosPath): Promise<AppNode | undefined> {
  if (this.type === AppNodeType.Root) {
    return this;
  }
  if (this.type === AppNodeType.Aios) {
    return await appNodeLoadAios.call(this, target);
  }
  switch (this.path.aiosType) {
    case AiosType.Account:
      await appNodeLoadAccount.call(this);
      break;
    case AiosType.Asset:
      await appNodeLoadAsset.call(this, target);
      break;
    case AiosType.Access:
      await appNodeLoadAccess.call(this, target);
      break;
    case AiosType.Action:
      await appNodeLoadAction.call(this, target);
      break;
  }
  return this;
}
