export enum AiosType {
  None = '',
  Aios = 'Aios',
  Auth = 'Auth',
  Account = 'Account',
  Asset = 'Asset',
  Access = 'Access',
  Action = 'Action',
  Audit = 'Audit',

  Chat = 'Chat',
  Mail = 'Mail',
  File = 'File',
  Task = 'Task',
}
