import { type ReactNode } from 'react';
import './Body.css';

export interface BodyProps {
  children: ReactNode;
  flow?: boolean;
}

export const Body = ({ children}: BodyProps): JSX.Element => {
  return (
    <div className='body'>
      {children}
      {/* <div className={!is(flow) ? 'body-menu' : 'body-flow'}>
        {children}
      </div> */}
    </div>
  );
};
