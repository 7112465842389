import { AppState } from 'signals/AppState/AppState';
import { Grid, GridCellButton, GridRow } from 'components';
import { getClass, appSignal } from 'signals';
import { is } from 'aios';
import './AppSettings.css';

export const AppSettings = (): JSX.Element => {
  if (!is(appSignal.value.settingsMenu)) {
    return <></>;
  }
  return (
    <div className={getClass('app-settings')}>
      <Grid cols={2} rows={3} width={20} height={30} float right>
        <GridRow>
          <GridCellButton
            name='dark'
            icon='dark'
            options={{ selected: !is(appSignal.value.light) }}
            onClick={async () => {
              appSignal.value.light = false;
              AppState.current.refresh();
            }} />
          <GridCellButton
            name='light'
            icon='light'
            options={{ selected: is(appSignal.value.light) }}
            onClick={async () => {
              appSignal.value.light = true;
              AppState.current.refresh();
            }} />
        </GridRow>
        <GridRow>
          <GridCellButton
            name='desktop'
            icon='desktop'
            options={{ selected: !is(appSignal.value.narrow) }}
            onClick={async () => {
              appSignal.value.narrow = false;
              AppState.current.refresh();
            }} />
          <GridCellButton
            name='narrow'
            icon='narrow'
            options={{ selected: is(appSignal.value.narrow) }}
            onClick={async () => {
              appSignal.value.narrow = true;
              AppState.current.refresh();
            }} />
        </GridRow>
        {/* <GridRow>
          <GridCellButton
            name='install'
            icon='install'
            options={{ selected: !is(appSignal.value.pwa) }}
            onClick={async () => {
              appSignal.value.pwa = false;
              AppState.current.refresh();
              // if (settings.install){
              //   unregister();
              // }
            }} />
          <GridCellButton
            name='uninstall'
            icon='uninstall'
            options={{ selected: is(appSignal.value.pwa) }}
            onClick={async () => {
              appSignal.value.pwa = true;
              AppState.current.refresh();
              // if (!settings.install){
              //   register()
              // }
            }} />
        </GridRow> */}
      </Grid>
    </div>
  );
};
