import { type Grid2CellProps, Grid2Cell } from './Grid2Cell';
import { is } from 'aios';
import TextField from '@mui/material/TextField';

export interface Grid2CellInputProps extends Grid2CellProps {
  value?: string;
  onChange?: (input: string) => void;
  center?: boolean;
}

export const Grid2CellInput = (props: Grid2CellInputProps): JSX.Element => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (is(props.onChange)) {
      props.onChange(e.currentTarget.value);
    }
  };
  return (
    <Grid2Cell {...props}>
      <TextField
        fullWidth
        value={props.value}
        onChange={onChange}
        disabled={props?.disabled}
        sx={{
          height: '100%',
          minHeight: 'auto',
          '& .MuiInputBase-root': {
            height: '100%',
            minHeight: 'auto',
          },
          '& .MuiInputBase-input': {
            textAlign: !is(props.center) ? 'left' : 'center',
          },
        }}
      />
    </Grid2Cell>
  );
};