import { getClass } from 'signals';
import { GridCell, type GridCellProps } from './GridCell';

export const GridCellText = (props: GridCellProps): JSX.Element => {
  const className = getClass('grid-cell-text', props.options);
  return (
    <GridCell {...props}>
      <div className={className}>
        <span data-testid={props.test}>{props.children}</span>
      </div>
    </GridCell>
  );
};