import { is } from 'aios';

export function getNewN(value?: string): number {
  if (is(value)) {
    const pattern = /new(\d+)/;
    const match = value.match(pattern);
    if (is(match) && is(match[1])) {
      return parseInt(match[1]);
    }
  }
  return -1;
}
