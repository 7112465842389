import { Body } from 'components';
import { AppSide } from '../AppSide';
import { AppData } from '../AppData';
import { AppChat } from '../AppChat';
import { AppSettings } from '../AppSettings';
import { getClass } from 'signals';

export const AppBody = (): JSX.Element => {
  const className = getClass('app-body');
  return (
    <Body>
      <div className={className}>
        <AppSide />
        <AppData />
        <AppChat />
        <AppSettings />
      </div>
    </Body>
  );
};
