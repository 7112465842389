import { useState } from 'react';
import { useApp, useRoute } from 'signals';
import { Grid2, Grid2Cell, Grid2CellIcon, Grid2Row } from 'components';
import { createAiosPath, is } from 'aios';
import { Grid2CellInput } from 'components/Grid2/Grid2CellInput';
import { Grid2CellButton } from 'components/Grid2/Grid2CellButton';

export const AppData = (): JSX.Element => {
  const { root } = useApp();
  const { path, goTo } = useRoute();
  const [isDragging, setIsDragging] = useState(false);
  function handleDragOver(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(true);
  }
  function handleDragLeave(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(false);
  }
  function handleDrop(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(false);
    const upload = async (): Promise<void> => {
      const node = root.find(path);
      if (!is(node)) {
        return;
      }
      const commands = node.interface.control?.commands;
      if (!is(commands)) {
        return;
      }
      const upload = commands.find(command => command.name === 'upload');
      if (!is(upload)) {
        return;
      }
      if (is(upload.onUpload)) {
        const handled = await upload.onUpload(e.dataTransfer.files);
        if (is(handled)) {
          return;
        }
      }
      if (is(upload.path)) {
        goTo(createAiosPath({ path: upload.path }));
      }
    }
    void upload();
  };
  if (path.path === '' || path.path === '/') {
    return (
      <div>AppContent no path</div>
    );
  }
  let node = root.find(path);
  if (!is(node)) {
    return (
      <div>AppContent no node</div>
    );
  }
  if (!is(path.action)) {
    if (is(node.default)) {
      node = node.default;
    }
  }
  const { control } = node.interface;
  const Form = control?.form;
  return (
    <div
      className={`app-data${!isDragging ? '' : ' drag'}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className='app-data-container'>
          <Grid2 size='2vw' cols={10}>
             <Grid2Row>
              <Grid2CellButton icon='folder'/>
              <Grid2CellInput span={2} value='folder/file' />
              <Grid2CellButton icon='create'/>
              <Grid2CellButton icon='left'/>
              <Grid2CellInput span={2} value='2/2' center />
              <Grid2CellButton icon='right'/>
              <Grid2CellButton icon='remove'/>
              <Grid2CellButton icon='more'/>
            </Grid2Row>
            <Grid2Row>
              <Grid2CellButton icon='data'/>
              <Grid2Cell span={2}>&nbsp;</Grid2Cell>
              <Grid2CellInput span={3} value='0.0.0' center />
              <Grid2CellButton icon='diff'/>
              <Grid2CellButton icon='text'/>
              <Grid2CellButton icon='code'/>
              <Grid2CellButton icon='image'/>
            </Grid2Row>
          </Grid2>          
            {/* {
              is(Form) ?
                (<Form node={node} />) :
                (<></>)
            } */}

        
      </div>
    </div>

  );
};

// <+2/2->