import { getClass } from 'signals';
import './Grid2.css';

export interface Grid2Props {
  size: string;
  cols: number;
  children: React.ReactNode;
}

export const Grid2 = ({ size, cols, children }: Grid2Props): JSX.Element => {
  const style = {
    gridTemplateColumns: `${size} 1fr repeat(${cols - 2}, ${size})`,
    gridAutoRows: size
  };
  let className = 'grid2';
  className = getClass(className);
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}