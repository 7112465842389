import { isFail, isWait, createAiosResult, getId, AiosStatus, type AiosException, is } from 'aios';
import { type AppNode } from 'app/node/AppNode';
import { appNodeSaveData } from './appNodeSaveData';

export async function appNodeSaveNext(this: AppNode): Promise<AppNode | undefined> {
  if (isFail(this.result.status)) {
    return this;
  }
  if (!isWait(this.result.status)) {
    this.result = createAiosResult({
      id: getId(),
      title: `saving '${this.path.path as string}'`,
      status: AiosStatus.Processing,
      progress: 0.1,
    });
    return this;
  }
  let nodes = this.nodes;
  if (is(nodes)) {
    nodes = nodes.filter((node) => !is(node.path.action));
  }
  if (is(nodes)) {
    try {
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      const promises = nodes.map((node) => appNodeSaveData.call(node));
      await Promise.all(promises);
      const fails = nodes.filter((node) => isFail(node.result.status));
      if (is(fails)) {
        this.result.setFail();
        return this;
      }
      const waits = nodes.filter((node) => isWait(node.result.status));
      if (is(waits)) {
        if (!is(this.result.progress) || this.result.progress === 0.9) {
          this.result.progress = 0.2;
        } else {
          this.result.progress = this.result.progress + 0.1;
        }
        return this;
      }
    } catch (exception) {
      this.result.setException(exception as AiosException);
      return this;
    }
  }
  return this;
}
