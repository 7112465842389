import {
  type AiosAsset,
  type AiosPath,
  AiosFileType,
  AiosType,
  add,
  createAiosAsset,
  createAiosFile,
  createAiosPath,
  is,
  isFile,
  isFail,
  AiosStatus,
  createAiosResult,
  getId,
  isWait,
} from 'aios';
import { type AppNode, createAppNode } from '../../AppNode';
import { appNodeLoadData } from '../load/appNodeLoadData';
import { createAiosAssetSample } from './createAiosAssetSample';
import { appNodeLoadAssetUi } from './appNodeLoadAssetUi';
import { getNewN } from 'app';

export async function appNodeLoadAsset(this: AppNode, target: AiosPath): Promise<void> {
  const data = this.data;
  if (is(data) && is(data.loaded)) {
    if (is(this.result.status) && !is(target.action)) {
      if (!isWait(this.result.status)) {
        this.result = createAiosResult();
      }
    }
    appNodeLoadAssetUi.call(this);
    return;
  }
  if (isFail(this.result.status)) {
    appNodeLoadAssetUi.call(this);
    return;
  }
  if (!is(this.path.version) && !is(this.path.mode) && !is(this.path.action)) {
    if (getNewN(this.path.name) > -1 || this.path.version === 'new') {
      appNodeLoadAssetNew.call(this);
      appNodeLoadAssetUi.call(this);
      return;
    }
    if (!isWait(this.result.status)) {
      appNodeLoadAssetProcess.call(this);
      appNodeLoadAssetUi.call(this);
      return;
    }
    await appNodeLoadAssetData.call(this);
    appNodeLoadAssetUi.call(this);
    return;
  }
  if (is(this.path.version)) {
    if (this.path.version === 'all') {
      appNodeLoadAssetUi.call(this);
      return;
    }
    if (this.path.version === 'new') {
      if (isWait(this.result?.status)) {
        appNodeLoadAssetUi.call(this);
        return;
      }
      const saving = this.nodes?.[0];
      if (is(saving) && isWait(saving.result?.status)) {
        appNodeLoadAssetUi.call(this);
        return;
      }
      const versions = this.parent;
      const asset = versions?.parent;
      const copy = asset?.data?.item as AiosAsset;
      if (is(copy)) {
        const path = createAiosPath({ path: copy.path, version: '0.0.0' });
        const asset = createAiosAsset({ ...copy, id: undefined, path: path.path, version: '0.0.0' })
        this.data = {
          type: AiosType.Asset,
          item: asset
        }
      }
      appNodeLoadAssetUi.call(this);
      return;
    }
    if (!isWait(this.result.status)) {
      appNodeLoadAssetProcess.call(this);
      appNodeLoadAssetUi.call(this);
      return;
    }
    await appNodeLoadAssetData.call(this);
    this.interface.switch({ chat: true, processing: this.result });
    appNodeLoadAssetUi.call(this);
    return;
  }
  appNodeLoadAssetUi.call(this);
}

function appNodeLoadAssetProcess(this: AppNode): void {
  this.result = createAiosResult({
    id: getId(),
    title: `loading asset '${this.path.full as string}'`,
    status: AiosStatus.Processing,
  });
  this.interface.switch({ processing: this.result });
}

function appNodeLoadAssetNew(this: AppNode): void {
  let parent = this.parent as AppNode;
  const parentData = parent.data;
  if (!is(parentData)) {
    return;
  }
  const parentPath = parent?.path?.path as string;
  let newName;
  let newItem;
  const files = parentData.files;
  if (!is(files)) {
    if (this.parent?.path.aiosType === AiosType.Account) {
      newItem = createAiosAsset({
        path: `${parentPath}/folder`,
        name: 'folder',
        file: createAiosFile({ type: AiosFileType.Folder }),
      });
    } else {
      if (parent.path.version === 'all') {
        parent = parent.parent as AppNode;
      }
      if (!isFile(parent?.path.fileType)) {
        newItem = createAiosAssetSample(AiosFileType.Text, parentPath);
      } else {
        const asset = parentData.item as AiosAsset;
        newItem = createAiosAsset({
          name: 'text.txt', // todo: this name?
          parent: `${parent?.path.account as string}/${parent?.path.assetPath as string}`,
          path: `${parent?.path.account as string}@0.0.0/${parent?.path.assetPath as string}`,
          version: '0.0.0',
          file: { ...asset.file },
        });
      }
    }
  } else {
    const file = files[0];
    newName = file.path as string;
    newItem = createAiosAsset({
      path: `${parentPath}/${newName}`,
      name: newName,
      file: createAiosFile({
        ...file,
        path: `${parentPath}/${newName}`,
        text: file.text,
      }),
    });
    if (is(this.parent)) {
      parentData.files = undefined;
    }
  }
  this.data = {
    type: AiosType.Asset,
    item: newItem,
    loaded: true,
  };
  this.result.setOk();
}

async function appNodeLoadAssetData(this: AppNode): Promise<boolean> {
  if (!is(await appNodeLoadData.call(this))) {
    return false;
  }
  const asset = createAiosAsset(this.data?.item);
  const { assets, versions, accesses } = asset;
  if (is(assets)) {
    for (let i = 0; i < assets.length; i++) {
      const child = assets[i];
      const childPath = createAiosPath({ path: child.path });
      let childNode = this.find(childPath);
      if (!is(childNode)) {
        childNode = createAppNode();
        this.nodes = add(this.nodes, childNode);
      }
      childNode.parent = this;
      childNode.path = childPath;
      childNode.id = child.id as string;
      childNode.name = child.name as string;
      childNode.data = { type: AiosType.Asset, item: child };
    }
  }
  if (is(versions)) {
    const versionsPath = createAiosPath({ path: this.path.path, version: 'all' });
    let versionNode = this.find(versionsPath);
    if (!is(versionNode)) {
      versionNode = createAppNode({ path: versionsPath, parent: this });
      this.nodes = add(this.nodes, versionNode);
    }
    for (let i = 0; i < versions.length; i++) {
      const child = versions[i];
      const childPath = createAiosPath({ path: child.path });
      let childNode = this.find(childPath);
      if (!is(childNode)) {
        childNode = createAppNode();
        versionNode.nodes = add(versionNode.nodes, childNode);
      }
      childNode.parent = versionNode;
      childNode.path = childPath;
      childNode.id = child.id as string;
      childNode.name = child.name as string;
      childNode.data = { type: AiosType.Asset, item: child };
    }
  }
  if (is(accesses)) {
    const accesesPath = createAiosPath({ path: this.path.path, mode: 'access' });
    let accessNode = this.find(accesesPath);
    if (!is(accessNode)) {
      accessNode = createAppNode({ path: accesesPath, parent: this });
      this.nodes = add(this.nodes, accessNode);
    }
    for (let i = 0; i < accesses.length; i++) {
      const child = accesses[i];
      const childPath = createAiosPath({
        path: this.path?.path,
        mode: 'access',
        modeId: child.id,
      });
      let childNode = this.find(childPath);
      if (!is(childNode)) {
        childNode = createAppNode();
        accessNode.nodes = add(accessNode.nodes, childNode);
      }
      childNode.parent = accessNode;
      childNode.id = child.id as string;
      childNode.path = childPath;
      childNode.name = 'access';
      childNode.data = { type: AiosType.Access, item: child };
    }
  }
  const useData = this.data;
  if (!is(useData)) {
    return false;
  }
  useData.item = asset;
  return true;
}
