import { useState } from 'react';
import { createAiosObject, is, set } from 'aios';
import { GridCell, GridCellButton, GridCellIcon, GridCellText, GridRow, JsonDisplay } from 'components';
import { type AppContentHistoryProps } from './AppContentHistory';
import { type ClassOptions } from 'signals';
import { formatDate, formatStatus } from './format';

export const AppContentHistoryEx = ({ id, history }: AppContentHistoryProps): JSX.Element => {
  const [more, setMore] = useState('moreex');
  const { to, from, name, date, text, result, process } = history;
  const options: ClassOptions = {
    show: true
  };
  const onMore = async (): Promise<void> => {
    if (more === 'lessex') {
      setMore('moreex');
    } else {
      setMore('lessex');
    }
  };
  const renderHead = (): JSX.Element => {
    let display = set(name, '?');
    if (is(to)) {
      display = `${formatDate(date)} - ${display}`;
      return (
        <GridRow>
          <GridCell options={{ ...options, borderTop: true, borderLeft: true }} height={5}>&nbsp;</GridCell>
          <GridCellText height={5} options={{ ...options, xMax: true, yMid: true, borderTop: true }}>{display}</GridCellText>
          <GridCellIcon height={5} options={{ ...options, borderTop: true, borderRight: true }} icon={to} name={name} />
        </GridRow>
      );
    }
    if (is(from)) {
      display = `${display} - ${formatDate(date)}`;
      return (
        <GridRow>
          <GridCellIcon height={5} options={{ ...options, borderTop: true, borderLeft: true }} icon={from} name={name} />
          <GridCellText height={5} options={{ ...options, yMid: true, borderTop: true }}>{display}</GridCellText>
          <GridCell height={5} options={{ ...options, borderTop: true, borderRight: true }} >&nbsp;</GridCell>
        </GridRow>
      );
    }
    return (<></>);
  }
  const renderBody = (): JSX.Element => {
    const subOptions = { ...options };
    if (more !== 'more') {
      subOptions.borderBottom = true;
    }
    const test = is(id) ? `result-${id}` : undefined;
    if (is(text)) return (
      <GridRow>
        <GridCell height={5} options={{ ...subOptions, borderLeft: true }}>&nbsp;</GridCell>
        <GridCellText height={5} options={{ ...subOptions, yMid: true }}>{text}</GridCellText>
        {
          (is(process)) ?
            (<GridCellButton options={{ ...subOptions, borderRight: true }} height={5} icon={more} onClick={onMore} />)
            :
            (<GridCell options={{ ...subOptions, borderRight: true }} height={5}>&nbsp;</GridCell>)
        }
      </GridRow>
    )
    if (is(result)) return (
      <GridRow>
        <GridCellIcon height={5} options={{ ...subOptions, borderLeft: true }} status={result?.status} />
        <GridCellText height={5} options={{ ...subOptions, yMid: true }}>
          <span>{result?.title}...</span>
          <span data-testid={test}>{formatStatus(result?.status)}</span>
        </GridCellText>
        {
          (is(result.results) || is(process)) ?
            (<GridCellButton options={{ ...subOptions, borderRight: true }} height={5} icon={more} onClick={onMore} />)
            :
            (<GridCell options={{ ...subOptions, borderRight: true }} height={5}>&nbsp;</GridCell>)
        }
      </GridRow>
    )
    return (<></>);
  }
  const renderMore = (): JSX.Element => {
    if (more === 'moreex' || !is(process)) {
      return (<></>);
    }
    const processObject = createAiosObject(process);
    return (
      <GridRow>
        <GridCell spanx={3}>
          <div style={{
            padding: '50px',
            maxHeight: '400px',
            overflowY: 'auto'
          }}>
            <JsonDisplay json={JSON.stringify(processObject)} />
          </div>          
        </GridCell>
      </GridRow>
    );    
    // return (
    //   <GridRow>
    //     <GridCell options={options}>&nbsp;</GridCell>
    //     <GridCell options={options}>
    //       <JsonDisplay json={JSON.stringify(processObject)} />
    //     </GridCell>
    //     <GridCell height={5} options={options}>&nbsp;</GridCell>
    //   </GridRow>
    // );
  }
  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderMore()}
      <GridRow>
        <GridCell spanx={3} space />
      </GridRow>
    </>
  );
};


