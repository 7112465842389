import {
  type AiosAccount,
  type AiosAsset,
  is,
  AiosDataCommandType,
  type AiosException,
  AiosStatus,
  isFail,
  AiosType,
} from 'aios';
import { type AppNode } from '../../AppNode';
import { createAiosClientApp, createAppCache } from 'app/util';

const cache = createAppCache();

export async function appNodeRemoveData(this: AppNode): Promise<AppNode | undefined> {
  const data = this.data;
  if (!is(data)) {
    return this;
  }
  const thisFull = this?.path.full as string;
  try {
    this.client = createAiosClientApp();
    const transaction = await this.client.send({
      path: this.path,
      command: {
        aiosType: data.type,
        commandType: AiosDataCommandType.Remove,
        item: data.item,
      },
    });
    this.client = undefined;
    if (this.result.status === AiosStatus.FailCancel) {
      return this;
    }
    const result = transaction.output;
    if (is(result)) {
      this.result.override(result);
    }
    if (isFail(this.result.status)) {
      return this;
    }
    await cache.remove(this.path?.full as string);
  } catch (exception) {
    this.result.setFail({ exception: exception as AiosException });
  }
  const parent = this.parent as AppNode;
  if (!is(parent)) {
    return;
  }
  const parentData = parent.data;
  if (!is(parentData)) {
    return;
  }
  const parentFull = parent.path.full as string;
  switch (parentData.type) {
    case AiosType.Account:
      {
        const account = parentData.item as AiosAccount;
        if (is(account.assets)) {
          account.assets = account.assets.filter((a) => a.path !== thisFull);
        }
        await cache.save(parentFull, account);
      }
      break;
    case AiosType.Asset:
      {
        const asset = parentData.item as AiosAsset;
        if (is(asset.assets)) {
          asset.assets = asset.assets.filter((a) => a.path !== thisFull);
        }
        await cache.save(parentFull, asset);
      }
      break;
  }
  return this;
}
