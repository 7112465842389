import { getDateKey, set } from '..';

export interface AiosData {
  accountId?: string;
  id?: string;
  date?: string;
  path?: string;
  parent?: string;
  child?: string;
}

export function createAiosData(options?: Partial<AiosData>): AiosData {
  const data = {
    accountId: set(options?.accountId, ''),
    id: set(options?.id, undefined),
    date: set(options?.date, getDateKey()),
    path: set(options?.path, ''),
    parent: set(options?.parent, ''),
    child: set(options?.child, ''),
  };
  return data;
}

export default AiosData;
