import { createAiosPath, AiosStatus, is, createAiosResult, delay } from 'aios';
import { type AppNode } from 'app/node/AppNode';
import { AppState } from 'signals/AppState/AppState';
import { AuthState } from 'signals/AppState/AuthState';

export async function doSignIn(this: AppNode): Promise<void> {
  const { full, goTo } = AppState.current;
  if (is(full)) {
    localStorage.setItem('aios_auth_path', full);
  }
  goTo(createAiosPath({ path: 'aios?action=signin' }));
}

export async function doSignInCancel(this: AppNode): Promise<void> {
  AuthState.current.signInCancel();
  this.result.setFail({ status: AiosStatus.FailCancel });
  this.interface.showHistory({ result: this.result });
  if (AppState.current.full === this.path.full) {
    AppState.current.goTo(createAiosPath({ path: 'aios' }));
  }
}

export async function doSignOut(this: AppNode): Promise<void> {
  const { goTo } = AppState.current;
  goTo(createAiosPath({ path: 'aios?action=signout' }));
}

export async function doSignOutConfirm(this: AppNode): Promise<void> {
  this.result = createAiosResult({
    title: 'signing out',
    status: AiosStatus.Processing,
  });
  this.interface.switch({ processing: this.result });
  AppState.current.refresh();
  void delay(async () => {
    if (!(await AuthState.current.signOut())) {
      this.result.setFail();
      this.interface.showHistory({ result: this.result });
      return;
    }
    window.location.href = '/aios';
    this.result.setOk();
  });
}

export async function doSignOutCancel(this: AppNode): Promise<void> {
  this.result.setFail({ status: AiosStatus.FailCancel });
  this.interface.showHistory({ result: this.result });
  AppState.current.goTo(createAiosPath({ path: 'aios' }));
  AppState.current.refresh();
}
