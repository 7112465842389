import {
  AiosStatus,
  createAiosResult,
  getId,
  is,
  isFail,
  AiosType,
  add,
  createAiosAccount,
  createAiosPath,
  isWait,
} from 'aios';
import { createAppNode, type AppNode } from '../../AppNode';
import { appNodeLoadAccountUi } from './appNodeLoadAccountUi';
import { appNodeLoadData } from '../load/appNodeLoadData';

export async function appNodeLoadAccount(this: AppNode): Promise<void> {
  const data = this.data;
  if (is(data) && is(data.loaded)) {
    appNodeLoadAccountUi.call(this);
    return;
  }
  if (isFail(this.result.status)) {
    appNodeLoadAccountUi.call(this);
    return;
  }
  if (!is(this.path.mode) && !is(this.path.action)) {
    if (!isWait(this.result.status)) {
      appNodeLoadAccountProcess.call(this);
    } else {
      await appNodeLoadAccountData.call(this);
    }
    appNodeLoadAccountUi.call(this);
    return;
  }
  switch (this.path.action) {
    case 'create':
    case 'update':
      // todo:   if (is(this.parent)) {
      //     if (isWait(this.parent.result.status)) {
      //       this.result = createAiosResult({
      //         id: getId(),
      //         title: `loading account '${thisFull}'`,
      //         status: AiosStatus.Processing,
      //       });
      //     } else {
      //       if (isWait(this.result.status)){
      //         this.result.setOk();
      //       }
      //     }
      //   }
      break;
    case 'delete':
      //   if (!(await appNodeLoadChildren.call(this))) {
      //     if (!is(this.result.progress)) {
      //       this.result.progress = 0;
      //     }
      //     this.result.progress += 0.25;
      //     if (this.result.progress === 1) {
      //       this.result.progress = 0.25;
      //     }
      //   } else {
      //     this.result.progress = 1;
      //   }
      break;
  }

  appNodeLoadAccountUi.call(this);
}

function appNodeLoadAccountProcess(this: AppNode): void {
  this.result = createAiosResult({
    id: getId(),
    title: `loading account '${this.path.full as string}'`,
    status: AiosStatus.Processing,
  });
  this.interface.switch({ processing: this.result });
}

async function appNodeLoadAccountData(this: AppNode): Promise<void> {
  if (!is(await appNodeLoadData.call(this))) {    
    return;
  }

  this.interface.switch({ chat: true, processing: this.result });
  const account = createAiosAccount(this.data?.item);
  if (is(account?.assets)) {
    for (let i = 0; i < account.assets.length; i++) {
      const child = account.assets[i];
      let childPath = createAiosPath({ path: child.path });
      childPath = createAiosPath({
        path: `${this.path?.path as string}/${childPath.name as string}`,
      });
      let childNode = this.find(childPath);
      if (!is(childNode)) {
        childNode = createAppNode();
        this.nodes = add(this.nodes, childNode);
      }
      childNode.parent = this;
      childNode.path = childPath;
      childNode.id = child.id as string;
      childNode.name = child.name as string;
      childNode.data = { type: AiosType.Asset, item: child };
    }
  }

  // todo: redirect
  // if (is(account.id) && is(account.default)) {
  //   const path = createAiosPath({ path: `${account.id}/${account.default}` });
  //   let node = await AppState.current.root.load(path);
  //   if (is(node)) {
  //     while (isWait(node?.result.status)) {
  //       node = await AppState.current.root.load(path);
  //     }
  //   }
  //   if (isOk(node?.result.status)) {
  //     this.default = node;
  //   }
  // }
}
