import ReactJson from 'react-json-view';
// import { useApp } from 'signals';
import { type AiosObject, createAiosObject, is } from 'aios';
import './JsonControl.css';

export interface JsonDisplayProps {
  json: string;
}

export const JsonDisplay = ({ json }: JsonDisplayProps): JSX.Element => {
  // const { settings } = useApp();
  // todo: const theme = is(settings.dark) ? 'bright' : undefined;
  const theme = 'bright';
  let valid = true;
  let clean: AiosObject = {};
  try {
    if (is(json)) {
      const parse = JSON.parse(json);
      clean = createAiosObject(parse) as AiosObject;
    }
  }
  catch (e) {
    valid = false;
  }
  if (!is(valid)) {
    return <div>invalid json</div>
  }
  return (
    <ReactJson
      src={clean}
      theme={theme}
      collapsed={true}
    />
  );
};

export default JsonDisplay;