import {
  type AiosServerChat,
  AiosStatus,
  AiosType,
  createAiosResult,
  createAiosPath,
  getId,
  isOk,
  is,
  delay,
  isWait,
  isFail,
  filesInject,
  filesExtract,
  AiosDataCommandType,
  createAiosDataCommand,
} from 'aios';
import { getAppNodeData, type AppNode } from 'app/node/AppNode';
import { AppState } from 'signals/AppState/AppState';
import { createAiosClientApp } from 'app/util';
import { saveAiosAssetText } from '../asset/createAiosAsset';

export async function doChat(this: AppNode, input?: string): Promise<void> {
  if (!is(input)) {
    return;
  }
  this.result = createAiosResult({
    id: getId(),
    act: 'chat',
    title: input,
    status: AiosStatus.Processing,
    progress: 0.1,
  });
  this.interface.showHistory({ result: this.result });
  this.interface.switch({ processing: this.result });
  void delay(async () => {
    await doChatData.call(this, input);
  });
  AppState.current.refresh();
}

export async function doChatCancel(this: AppNode): Promise<void> {
  if (this.result.progress === 0.1) {
    this.result.setFail({ status: AiosStatus.FailCancel });
    this.interface.switch({ chat: true, processing: this.result });
    AppState.current.refresh();
  }
  if (is(this.client)) {
    this.client.cancel();
  }
}

async function doChatData(this: AppNode, input: string): Promise<void> {
  if (!is(this.result)) {
    return;
  }
  if (!isWait(this.result.status)) {
    return;
  }
  this.result.progress = 0.2;
  input = embedTexts.call(this, input);
  const path = createAiosPath({ path: 'aios' });
  const command = createAiosDataCommand<AiosServerChat>({
    aiosType: AiosType.Chat,
    commandType: AiosDataCommandType.Send,
    item: { input },
  });
  this.client = createAiosClientApp();
  this.process = await this.client.send({ path, command });
  const result = this.process.output;
  if (is(result)) {
    if (isFail(result.status)) {
      this.result.override(result);
      this.interface.switch({ chat: true, processing: this.result, process: this.process });
    } else {
      this.result.setOk();
      this.interface.switch({ chat: true, processing: this.result, process: this.process });
      this.result = result;
      if (isOk(this.result.status)) {
        const chat = result.item as AiosServerChat;
        const text = extractTexts.call(this, chat.output);
        this.interface.showHistory({
          id: this.result.id,
          from: 'aios',
          name: 'openai',
          text,
          result: this.result,
          process: this.process,
        });
        if (this.path.action !== 'update') {
          AppState.current.goTo(createAiosPath({ path: this.path.path, action: 'update' }));
        }
      }
    }
  }
  AppState.current.refresh();
}

export async function doChatLoad(this: AppNode): Promise<void> {
  const path = createAiosPath({ path: 'aios/chat' });
  const command = createAiosDataCommand<AiosServerChat>({
    aiosType: AiosType.Chat,
    commandType: AiosDataCommandType.Load,
  });
  this.client = createAiosClientApp();
  this.process = await this.client.send({ path, command });
  const result = this.process.output;
  const items = result?.items;
  if (is(items)) {
    for (const item of items) {
      const chat = item as AiosServerChat;
      this.interface.showHistory({
        to: 'ai',
        from: 'you',
        text: chat.input,
      });
      this.interface.showHistory({
        to: 'you',
        from: 'ai',
        text: chat.output,
      });
    }
  }
}

function embedTexts(this: AppNode, input: string): string {

  const { path, text } = getAppNodeData(this);
  if (is(path) && is(text)) {
    const files = {
      [path]: text
    };
    input = filesInject(input, files);
  }

  return input;

  // const nodes = this.nodes;
  // if (is(nodes)) {
  //   for (const node of nodes) {
  //     embedText.call(node, input);
  //   }
  // }
  // return embedText.call(this, input);
}

// function embedText(this: AppNode, input: string): string {
//   const asset = this.data?.item as AiosAsset;
//   if (!is(asset)) {
//     return input;
//   }
//   const name = asset.file?.path;
//   const text = asset.file?.text;
//   if (is(name) && is(text)) {
//     input = `
//       ${input}
//       >>>${name}<<<
//       ${text}
//       >>>${name}<<<
//       `;
//   }
//   return input;
// }

function extractTexts(this: AppNode, input?: string): string | undefined {
  if (!is(input)) {
    return input;
  }
  const parts = filesExtract(input);
  const entries = Object.entries(parts);
  for (const entry of entries) {
    const [key, value] = entry;
    if (key === 'response') {
      input = value;
    } else {
      saveAiosAssetText.call(this, key, value);
    }
    // }
    // const parts = extractText(input);
    // const entries = Object.entries(parts);
    // for (const entry of entries) {
    //   const [key, value] = entry;
    //   if (key === 'response') {
    //     input = value;
    //   } else {
    //     saveAiosAssetText.call(this, key, value);
    //   }
  }
  return input;
}

// function extractText(text: string): Record<string, string> {
//   const delimiterRegex = />>>([^>]+?)<<<\n?([\s\S]+?)\n?>>>[^>]+?<<</g;
//   const contents: Record<string, string> = {};
//   const firstDelimiterIndex = text.search(/>>>[^>]+?<<</);
//   if (firstDelimiterIndex > 0) {
//     contents.response = text.substring(0, firstDelimiterIndex).trim();
//   } else if (firstDelimiterIndex === -1) {
//     return { response: text.trim() };
//   }
//   text = '\n' + text;
//   let match;
//   while ((match = delimiterRegex.exec(text)) !== null) {
//     const filename = match[1].trim().toLowerCase();
//     const content = match[2].trim();
//     contents[filename] = content;
//   }
//   return contents;
// }
