import {
  type AiosAccount,
  type AiosAccess,
  type AiosAsset,
  type AiosPath,
  createAiosPath,
  AiosType,
  is,
  add,
  isChildPath,
  isFail,
} from 'aios';
import { type AppNode } from '../../AppNode';
import { createAppCache } from 'app/util';
import { appNodeSaveData } from './appNodeSaveData';

const cache = createAppCache();

export async function appNodeSave(this: AppNode, target?: AiosPath): Promise<AppNode | undefined> {
  let node;
  if (!is(target)) {
    target = this.path;
  }
  const thisFull = this.path.full as string;
  const targetFull = target.full as string;
  if (thisFull !== targetFull) {
    node = await appNodeSaveNodes.call(this, target);
  } else {
    node = await appNodeSaveNode.call(this);
  }
  return node;
}

async function appNodeSaveNodes(this: AppNode, target: AiosPath): Promise<AppNode | undefined> {
  if (is(this.nodes)) {
    const targetFull = target.full as string;
    for (let i = 0; i < this.nodes.length; i++) {
      const child = this.nodes[i];
      const childFull = child.path?.full as string;
      if (isChildPath(child.path, target)) {
        if (targetFull === childFull) {
          await appNodeSaveNode.call(child);
          if (isFail(child.result.status)) {
            return child;
          }
          const data = this.data;
          if (!is(data)) {
            return child;
          }
          let parent = this.parent as AppNode;
          this.result = child.result;
          switch (data.type) {
            case AiosType.Asset:
              {
                const newAsset = data.item as AiosAsset;
                this.path = createAiosPath({ full: newAsset.path });
                this.name = newAsset.name as string;
              }
              break;
            case AiosType.Access:
              {
                const newAccess = data.item as AiosAccess;
                this.path = createAiosPath({ full: newAccess.path, modeId: newAccess.id });
                this.name = this.path.name as string;
                parent = parent.parent as AppNode;
              }
              break;
          }
          if (is(parent)) {
            const childData = child.data;
            const parentData = parent.data;
            if (is(parentData) && is(childData)) {
              const parentFull = parent.path.full as string;
              switch (parentData.type) {
                case AiosType.Account:
                  {
                    const account = parentData.item as AiosAccount;
                    account.assets = add(account.assets, childData.item);
                    await cache.save(parentFull, account);
                  }
                  break;
                case AiosType.Asset:
                  {
                    const asset = parentData.item as AiosAsset;
                    switch (childData.type) {
                      case AiosType.Asset:
                        asset.assets = add(asset.assets, childData.item);
                        break;
                      case AiosType.Access:
                        asset.accesses = add(asset.accesses, childData.item);
                        break;
                    }
                    await cache.save(parentFull, asset);
                  }
                  break;
              }
            }
          }
          this.interface.showHistory({ result: this.result });
          this.nodes = this.nodes.filter((n) => n.path.full !== child.path.full);
          return this;
        }
        return await appNodeSaveNodes.call(child, target);
      }
    }
  }
  return undefined;
}

async function appNodeSaveNode(this: AppNode): Promise<AppNode | undefined> {
  const save = this.parent;
  if (is(save)) {
    await appNodeSaveData.call(save);
    this.result.override(save.result);
  }
  return this;
}
