import { is, isWait, type AiosPath } from 'aios';
import { type AppNode } from '../../AppNode';
import { AuthFormAws } from 'components';
import { doSignIn, doSignInCancel, doSignOutCancel, doSignOutConfirm } from './doSign';
import { doChatCancel } from '../chat';
import { AuthState } from 'signals/AppState/AuthState';

export function appNodeLoadAiosUi(this: AppNode, target: AiosPath): void {
  const { action } = target;
  this.interface.showControl();
  if (!is(action)) {
    if (isWait(this.result.status)) {
      this.interface.showControl({
        commands: [
          {
            name: 'cancel',
            onChange: doChatCancel,
            blink: true,
          },
        ],
      });
      return;
    }
    appNodeLoadAiosUiDefault.call(this);
    return;
  }
  switch (this.result.progress) {
    case 0.1:
      this.interface.showControl({
        form: AuthFormAws,
        commands: [
          {
            path: 'aios',
            name: 'cancel',
            onChange: doSignInCancel,
          },
        ],
      });
      break;
    case 0.2:
      this.interface.showControl({
        commands: [
          {
            path: 'aios',
            name: 'cancel',
            blink: true,
            onChange: doSignInCancel,
          },
        ],
      });
      break;
    case 0.6:
      this.interface.showControl({
        commands: [
          {
            name: 'decline',
            path: this.path.path,
            onChange: doSignOutCancel,
          },
          {
            name: 'confirm',
            onChange: doSignOutConfirm,
          },
        ],
      });
      break;
  }
}

function appNodeLoadAiosUiDefault(this: AppNode): void {
  const commands = [];
  const account = AuthState.current.account;
  if (!is(account)) {
    commands.push({
      name: 'sign in',
      icon: 'profile',
      onChange: doSignIn,
    });
  } else {
    // todo: if (account.email === 'devops@prodev.ai') {
    //   commands.push({
    //     name: 'admin',
    //     icon: 'settings',
    //     path: '/aios?mode=admin'
    //   });
    // }
    commands.push({
      name: 'sign out',
      icon: 'profile',
      onChange: doSignOutConfirm,
    });
  }
  this.interface.showControl({ commands });
  this.interface.switch({ chat: true });
}
