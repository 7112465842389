import {
  type AiosObject,
  type AiosResult,
  AiosStatus,
  AiosType,
  createAiosObject,
  createAiosResult,
} from '../AiosType';
import type { AiosData } from './AiosData';
import { is, set } from '../functions';

export interface AiosDataResult<T extends AiosData> extends AiosResult {
  type?: AiosType;
  data?: string;
  item?: T;
  items?: T[];
  results?: Array<Partial<AiosDataResult<AiosData>>>;
  setOk: (options?: Partial<AiosDataResult<T>>) => AiosDataResult<T>;
  setFail: (options?: Partial<AiosDataResult<T>>) => AiosDataResult<T>;
  override: (result: AiosDataResult<T>) => AiosDataResult<T>;
  format: () => AiosObject;
}

export function createAiosDataResult<T extends AiosData>(
  options?: Partial<AiosDataResult<T>>,
): AiosDataResult<T> {
  const base = createAiosResult(options) as AiosDataResult<T>;
  const result = {
    ...base,
    type: set(options?.type, AiosType.None),
    data: set(options?.data, ''),
    item: set(options?.item, undefined),
    items: set(options?.items, undefined),
    setOk: set(
      options?.setOk,
      function (
        this: AiosDataResult<T>,
        values?: Partial<AiosDataResult<T>>,
      ): AiosDataResult<T> {
        base.setOk.call(this, values);
        this.item = set(values?.item, this.item);
        this.items = set(values?.items, this.items);
        return this;
      },
    ),
    setFail: set(
      options?.setFail,
      function (
        this: AiosDataResult<T>,
        values?: Partial<AiosDataResult<T>>,
      ): AiosDataResult<T> {
        base.setFail.call(this, values);
        if (this.status !== AiosStatus.FailAccess) {
          this.item = set(values?.item, this.item);
          this.items = set(values?.items, this.items);
        } else {
          this.item = undefined;
          this.items = undefined;
        }
        return this;
      },
    ),
    override: set(
      options?.override,
      function (
        this: AiosDataResult<T>,
        values: AiosDataResult<T>,
      ): AiosDataResult<T> {
        base.override.call(this, values);
        if (this.status !== AiosStatus.FailAccess) {
          this.item = set(values?.item, this.item);
          this.items = set(values?.items, this.items);
        } else {
          this.item = undefined;
          this.items = undefined;
        }
        return this;
      },
    ),
    format: set(options?.format, function (this: AiosDataResult<T>) {
      const format = base.format.call(this);
      if (is(this.item)) {
        format.item = JSON.stringify(createAiosObject(this.item));
      }
      if (is(this.items)) {
        const items: string[] = [];
        for (const item of items) {
          const child = createAiosObject(item) as AiosObject;
          items.push(JSON.stringify(child));
        }
        format.items = items;
      }
      return format;
    }),
  };
  return result;
}

export default AiosDataResult;
