import { type AiosAccess, createAiosAccess } from '../AiosAccess';
import {
  add,
  type AiosData,
  type AiosDispatch,
  type AiosFile,
  type AiosFileType,
  createAiosData,
  createAiosFile,
  createAiosPath,
  is,
  set,
  updateAiosFile,
} from '../AiosUtil';

export interface AiosAsset extends AiosData {
  name?: string;
  file?: AiosFile;
  assets?: AiosAsset[];
  default?: string;
  version?: string;
  versions?: AiosAsset[];
  accesses?: AiosAccess[];
  dispatch?: AiosDispatch;
}

export function createAiosAsset(options?: Partial<AiosAsset>): AiosAsset {
  const path = createAiosPath({ path: options?.path });
  const base = createAiosData(options);
  const asset: AiosAsset = {
    ...base,
    name: set(options?.name, undefined),
    default: set(options?.default, undefined),
    version: set(options?.version, path.version),
    accesses: set(options?.accesses, undefined),
    dispatch: set(options?.dispatch, undefined),
  };
  if (!is(options)) {
    return asset;
  }
  if (is(options.file)) {
    asset.file = createAiosFile({
      ...options.file,
      path: set(options.file?.path, path.path),
    });
  }
  const { assets, accesses, versions } = options;
  if (is(assets)) {
    asset.assets = [];
    for (let i = 0; i < assets.length; i++) {
      asset.assets = add(asset.assets, createAiosAsset(assets[i]));
    }
  }
  if (is(accesses)) {
    asset.accesses = [];
    for (let i = 0; i < accesses.length; i++) {
      asset.accesses = add(asset.accesses, createAiosAccess(accesses[i]));
    }
  }
  if (is(versions)) {
    asset.versions = [];
    for (let i = 0; i < versions.length; i++) {
      asset.versions = add(asset.versions, createAiosAsset(versions[i]));
    }
  }
  return asset;
}

export function updateAiosAsset(
  asset: AiosAsset,
  options?: Partial<AiosAsset>,
): AiosAsset {
  if (!is(options)) {
    return asset;
  }
  if (is(options.path) && asset.path !== options?.path) {
    const parts = options.path.split('/');
    if (parts.length > 1) {
      asset.name = parts[parts.length - 1];
    }
    asset.path = set(options?.path, '');
    if (is(asset.file)) {
      asset.file.path = asset.path;
    }
    return asset;
  }
  if (is(options?.name) && asset.name !== options?.name) {
    if (is(asset.path) && is(asset.name)) {
      asset.path = asset.path.replace(asset.name, options.name);
      if (is(asset.file)) {
        asset.file.path = asset.path;
      }
    }
    asset.name = set(options?.name, '');
    return asset;
  }
  if (is(options?.file)) {
    if (!is(asset.file)) {
      asset.file = createAiosFile(options.file);
    } else {
      asset.file = updateAiosFile(asset.file, options.file);
    }
    asset.path = set(options?.path, asset.file.path);
  }
  if (is(options.version) && asset.version !== options.version) {
    const path = createAiosPath({
      path: asset.path,
      version: options.version,
    });
    return createAiosAsset({
      ...asset,
      path: path.path,
      version: options.version,
      file: { ...asset.file, path: path.path },
    });
  }
  return asset;
}

export function getAssetData(
  this: AiosAsset,
): {
  path?: string;
  file?: AiosFile;
  type?: AiosFileType;
  text?: string;
  base?: string;
} {
  const path = this?.path;
  const file = this?.file;
  const type = file?.type;
  const text = file?.text;
  const base = file?.base;
  return { path, file, type, text, base };
}

export default AiosAsset;
