import { createAiosResult, getId, AiosStatus, delay, isWait, is, isFail } from 'aios';
import { type AppNode } from 'app/node/AppNode';
import { AppState } from 'signals/AppState/AppState';
import { appNodeSaveNext } from './appNodeSaveNext';

export function doSave(this: AppNode): void {
  if (isFail(this.result.status)) {
    return;
  }
  if (isWait(this.result.status)) {
    return;
  }
  this.result = createAiosResult({
    id: getId(),
    title: `staging save '${this.path.path as string}'`,
    status: AiosStatus.Processing,
    progress: 0.1,
  });
  const save = this.parent;
  if (!is(save)) {
    return;
  }
  save.result = createAiosResult({
    id: this.result.id,
    title: `confirming save '${save.path.path as string}'?`,
    status: AiosStatus.Processing,
    progress: 0.1,
  });
  if (is(save.nodes)) {
    for (const child of save.nodes) {
      if (!is(child.path.mode) && !is(child.path.action)) {
        child.result = createAiosResult({
          id: getId(),
          title: `confirming save '${child.path.path as string}'?`,
          status: AiosStatus.Processing,
          progress: 0.1,
        });
      }
    }
  }
}

export async function doSaveCancel(this: AppNode): Promise<void> {
  this.result.setFail({ status: AiosStatus.FailCancel });
  // todo: still need to wait for client to finish for no rouge finishes
  // if (is(this.client)) {
  //   this.client.cancel();
  // }
}

export async function doSaveConfirm(this: AppNode): Promise<void> {
  this.result = createAiosResult({
    id: this.result.id,
    title: `saving nodes'${this.path.path as string}'.`,
    status: AiosStatus.Processing,
    progress: 0.5,
  });
  this.interface.showProcessing(this.result);
  void delay(async (): Promise<void> => {
    await doSaveNext.call(this);
  });
  AppState.current.refresh();
}

async function doSaveNext(this: AppNode): Promise<void> {
  if (isFail(this.result.status)) {
    return;
  }
  if (!is(this.result.progress)) {
    return;
  }
  const save = this.parent;
  if (!is(save)) {
    return;
  }
  await appNodeSaveNext.call(save);
  if (isWait(save.result.status)) {
    if (this.result.progress === 0.8) {
      this.result.progress = 0.6;
    } else {
      this.result.progress = this.result.progress + 0.1;
    }
    void delay(async () => {
      await doSaveNext.call(this);
    });
    AppState.current.refresh();
    return;
  }
  this.result.setOk();
  AppState.current.refresh();
}

export async function doSubmit(this: AppNode): Promise<void> {
  const doSubmit = this.interface.control?.doSubmit;
  if (is(doSubmit)) {
    void doSubmit();
  }
}

export async function doReset(this: AppNode): Promise<void> {
  const doReset = this.interface.control?.doReset;
  if (is(doReset)) {
    doReset();
  }
}
