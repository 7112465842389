export function filesExtract(text: string): Record<string, string> {
  const delimiterRegex =
    /\/\/\s*([^\s]+)\s*start\s*\n([\s\S]*?)\n\s*\/\/\s*\1\s*finish/g;
  const contents: Record<string, string> = {};
  const firstDelimiterIndex = text.search(/\/\/\s*[^\s]+?\s*start/);

  if (firstDelimiterIndex > 0) {
    contents.response = text.substring(0, firstDelimiterIndex).trim();
  } else if (firstDelimiterIndex === -1) {
    return { response: text.trim() };
  }

  text = '\n' + text;
  let match;
  while ((match = delimiterRegex.exec(text)) !== null) {
    const filename = match[1].trim().toLowerCase();
    const content = match[2].trim();
    contents[filename] = content;
  }

  return contents;
}
