import { getClass } from 'signals';

export interface Grid2CellProps {
  test?: string;
  span?: number;
  spin?: boolean;
  color?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Grid2Cell = ({ span = 1, children }: Grid2CellProps): JSX.Element => {
  const style = {
    gridColumn: `span ${span}`,
  };
  let className = 'grid2-cell';
  className = getClass(className);
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
}