import { type AiosPath, anon, is } from 'aios';
import { type RJSFSchema } from '@rjsf/utils';

export function getFormSchema(path: AiosPath): RJSFSchema {
    let schema;
    if (!is(path.assetPath) || !path.assetPath.includes('/')) {
        schema = FolderSchema;
        return schema as RJSFSchema;
    }
    if (!is(path.version)) {
        schema = FileSchema;
        return schema as RJSFSchema;
    }
    if (!is(path.mode)){
        schema = VersionSchema;
        return schema as RJSFSchema;    
    }
    schema = AccessSchema;
    return schema as RJSFSchema;
}

const FolderSchema = {
    title: 'folder',
    type: 'object',
    required: ['path', 'name'],
    properties: {
        path: {
            type: 'string',
            title: 'path',
        },
        name: {
            type: 'string',
            title: 'name',
        },
    },
};

const FileSchema = {
    title: 'file',
    type: 'object',
    required: ['path', 'name', 'file'],
    properties: {
        path: {
            type: 'string',
            title: 'path',
        },
        name: {
            type: 'string',
            title: 'name',
        },
        file: {
            title: 'data',
            type: 'object',
            properties: {
                type: {
                    title: 'type',
                    type: 'string',
                    enum: [
                        'Folder',
                        'Markdown',
                        'Data',
                        'Text',
                        'Code',
                        'Json',
                        'Image',
                        'Audio',
                        'Model',
                        'Video',
                    ],
                },
                text: {
                    title: 'text',
                    type: 'string',
                },
                base: {
                    title: 'base',
                    type: 'string',
                },
                mime: {
                    title: 'mime',
                    type: 'string',
                },
            },
        },
    },
};

const VersionSchema = {
    title: 'version',
    type: 'object',
    required: ['path', 'name', 'version', 'file'],
    properties: {
        path: {
            type: 'string',
            title: 'path',
        },
        name: {
            type: 'string',
            title: 'name',
        },
        version: {
            type: 'string',
            title: 'version',
        },
        file: {
            title: 'data',
            type: 'object',
            properties: {
                type: {
                    title: 'type',
                    type: 'string',
                    enum: [
                        'Folder',
                        'Markdown',
                        'Data',
                        'Text',
                        'Code',
                        'Json',
                        'Image',
                        'Audio',
                        'Model',
                        'Video',
                    ],
                },
                text: {
                    title: 'text',
                    type: 'string',
                },
                base: {
                    title: 'base',
                    type: 'string',
                },
                mime: {
                    title: 'mime',
                    type: 'string',
                },
            },
        },
    },
};

const AccessSchema = {
    title: 'Access',
    type: 'object',
    required: ['type', 'actorId'],
    properties: {
      type: {
        title: 'type',
        type: 'string',
        enum: [
          '',
          'Read',
          'Run',
        ],
        default: 'Read',
      },
      actorId: {
        type: 'string',
        title: 'actorId',
        default: anon,
      },
    },
  };