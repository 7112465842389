import { type GridCellProps, GridCell } from './GridCell';
import TextField from '@mui/material/TextField';
import { is } from 'aios';

export interface GridCellInputMultiProps extends GridCellProps {
  value?: string;
  onChange?: (input: string) => void;
}

export const GridCellInputMulti = (props: GridCellInputMultiProps): JSX.Element => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (is(props.onChange)) {
      props.onChange(e.currentTarget.value);
    }
  };
  return (
    <GridCell {...props}>
      <TextField
        value={props.value}
        onChange={onChange}
        disabled={props.options?.disabled}        
        placeholder='chat here...'
        sx={{ height: '100%' }}

        // multiline
        // // fullWidth
        // sx={{ margin: '4px' }}
        
        
        // sx={{
        //   '& .MuiInputBase-input': {
        //     display: 'flex',
        //     alignItems: 'center',
        //     // justifyContent: 'center',
        //     textAlign: 'center',
        //     padding: 0, // Reset padding to ensure text is centered
        //     height: '100%',
        //     lineHeight: 'normal', // Ensures correct line height
        //   },
        // }}
        // className='grid-cell-multiline'

      />
    </GridCell>
  );
};