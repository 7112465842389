import { type AiosException, AiosStatus, createAiosResult, getId, isFail, isWait, is } from 'aios';
import { type AppNode } from '../../AppNode';
import { appNodeRemoveData } from './appNodeRemoveData';

export async function appNodeRemoveLast(this: AppNode): Promise<AppNode | undefined> {
  if (isFail(this.result.status)) {
    return this;
  }
  if (!isWait(this.result.status)) {
    this.result = createAiosResult({
      id: getId(),
      title: `deleting '${this.path.full as string}'`,
      status: AiosStatus.Processing,
      progress: 0.1,
    });
    return this;
  }
  let nodes = this.nodes;
  if (is(nodes)) {
    nodes = nodes.filter((node) => !is(node.path.action));
  }
  if (is(nodes)) {
    try {
      // todo: // eslint-disable-next-line @typescript-eslint/promise-function-async
      // const promises = nodes.map((node) => appNodeRemoveLast.call(node));
      // await Promise.all(promises);
      for (const node of nodes) {
        await appNodeRemoveLast.call(node);

      }
      const fails = nodes.filter((node) => isFail(node.result.status));
      if (is(fails)) {
        this.result.setFail();
        return this;
      }
      const waits = nodes.filter((node) => isWait(node.result.status));
      if (is(waits)) {
        if (!is(this.result.progress) || this.result.progress === 0.9) {
          this.result.progress = 0.2;
        } else {
          this.result.progress = this.result.progress + 0.1;
        }
        return this;
      }
    } catch (exception) {
      this.result.setException(exception as AiosException);
      return this;
    }
  }
  if (this.path.version === 'all' || (this.path.mode === 'access' && !is(this.path.modeId))){
    this.result.setOk();
    return this;
  }
  await appNodeRemoveData.call(this);
  // await sleep(5000);
  // this.result.setOk();
  return this;
}
