import { type ReactNode } from 'react';

export interface Grid2RowProps {
  children?: ReactNode;
}

export const Grid2Row = ({ children }: Grid2RowProps): JSX.Element => {
  return (
    <>
      {children}
    </>
  );
}