import { type AiosData, is } from 'aios';
import { Grid, GridCell, GridCellButton, GridRow } from 'components';

export interface ListProps {
  name?: string;
  types?: Array<{ title: string; field: string; size: number }>;
  items?: AiosData[];
  onSelect?: (id: string) => void;
}

export const List = ({ name, types, items, onSelect }: ListProps): JSX.Element => {
  const renderName = (): JSX.Element => {
    if (!is(name)) {
      return (<></>);
    }
    return (
      <GridRow>
        <GridCell>
          {name}
        </GridCell>
      </GridRow>
    );
  }
  const renderTypes = (): JSX.Element => {
    // const cols: JSX.Element[] = [];
    // for (let t = 0; t < types.length; t++) {
    //   const { title } = types[t];
    //   cols.push(
    //     <GridCell key={`list-item-head-${t}`}>
    //       {title}
    //     </GridCell>
    //   );
    // }
    // rows.push(<GridRow key={'list-item-head'}>{cols}</GridRow>);
    if (!is(types)) {
      return (<></>);
    }
    return (<></>);
  }
  const renderItems = (): JSX.Element => {
    if (!is(types) || !is(items)) {
      return (
        <GridRow>
          <GridCell>
            on items
          </GridCell>
        </GridRow>
      );
    }
    const rows: JSX.Element[] = [];
    for (let i = 0; i < items.length; i++) {
      const cells: JSX.Element[] = [];
      const item = items[i];
      for (let t = 0; t < types.length; t++) {
        const { field } = types[t];
        const key = `list-item-row-${i}-item-${t}`;
        const value = item?.[field as keyof AiosData];
        if (field === 'id') {
          cells.push(
            <GridCellButton key={key} name={value} onClick={async () => {
              if (is(onSelect)) {
                onSelect(item.id as string);
              }
            }} />
          );
        } else {
          cells.push(
            <GridCell key={key}>
              {value}
            </GridCell>
          );
        }
      }
      rows.push(<GridRow key={`list-item-row-${i}`}>{cells}</GridRow>);
    }
    return (<>{rows}</>)
  }
  return (
    <Grid cols={1} width={100} >
      {renderName()}
      {renderTypes()}
      {renderItems()}
    </Grid>
  );
};
