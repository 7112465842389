import { type AiosPath, AiosStatus, createAiosResult, getId, is } from 'aios';
import { type AppNode } from '../../AppNode';
import { AuthState } from 'signals/AppState/AuthState';
import { appNodeLoadAiosUi } from './appNodeLoadAiosUi';

export async function appNodeLoadAios(
  this: AppNode,
  target: AiosPath,
): Promise<AppNode | undefined> {
  const { path } = target;
  if (!is(path)) {
    return this;
  }
  const lcpath = path.toLowerCase();
  if (lcpath === 'aios') {
    await appNodeLoadAiosDefault.call(this, target);
  } else {
    await appNodeLoadAiosAdditional.call(this, target);
  }
  appNodeLoadAiosUi.call(this, target);
  return this;
}

async function appNodeLoadAiosDefault(
  this: AppNode,
  target: AiosPath,
): Promise<AppNode | undefined> {
  const { action } = target;
  switch (action) {
    case 'auth':
    case 'signin':
      await appNodeLoadAiosSignIn.call(this);
      break;
    case 'signout':
      await appNodeLoadAiosSignOut.call(this);
      break;
    default:
      // await doChatLoad.call(this);
      break;
    
    // todo: default:
    //   if (!isWait(this.result.status)) {
    //     this.result = createAiosResult({
    //       id: getId(),
    //       title: 'loading content',
    //       status: AiosStatus.Processing,
    //     });
    //   } else {
    //     this.result.setOk();
    //     this.nodes = add(this.nodes, createAppNode({
    //       parent: this,
    //       path: createAiosPath({ path: 'aios/content' }),
    //       name: 'content',
    //     }));
    //   }
    //   break;
  }
  return this;
}

async function appNodeLoadAiosSignIn(this: AppNode): Promise<void> {
  await AuthState.current.signIn();
}

async function appNodeLoadAiosSignOut(this: AppNode): Promise<void> {
  if (this.result.status !== AiosStatus.Processing) {
    this.result = createAiosResult({
      id: getId(),
      title: 'sign out? - local cache will be cleared',
      status: AiosStatus.Processing,
      progress: 0.6,
    });
    this.interface.switch({ processing: this.result });
  }
}

async function appNodeLoadAiosAdditional(
  this: AppNode,
  target: AiosPath,
): Promise<AppNode | undefined> {

  // if (lcpath === 'aios/file') {
  //   appNodeLoadAiosUi.call(this, target);
  //   return this;
  // }
  // const { action } = target;
  // switch (action) {
  //   case 'auth':
  //   case 'signin':
  //     await appNodeLoadAiosSignIn.call(this);
  //     break;
  //   case 'signout':
  //     await appNodeLoadAiosSignOut.call(this);
  //     break;
  // }
  return this;
}
