import { GridCell, GridCellButton, GridCellIcon, GridCellInputMulti, GridEx, GridRow, JsonEditor } from 'components';
import { AiosFileType, is } from 'aios';
import { getAppNodeData, doInvoke, doChat } from 'app';
import { useState } from 'react';
import { type AppControlType, type AppControlProps } from './AppControlType';
import { type ClassOptions } from 'signals';

export const AppContentChat: AppControlType = ({ node, show, hide }: AppControlProps) => {
  const [input, setInput] = useState('');
  const [more, setMore] = useState('less');
  const [mode, setMode] = useState('json');
  const [text, setText] = useState('');
  const options: ClassOptions = {
    show: is(show) ? true : undefined,
    hide: is(hide) ? true : undefined,
  };
  if (!is(node)) {
    return (<div>AppContentChat no node</div>);
  }
  const { file } = getAppNodeData(node);
  const onMore = async (): Promise<void> => {
    if (more === 'less') {
      setMore('more');
    } else {
      setMore('less');
    }
  }
  const onMode = async (): Promise<void> => {
    if (mode === 'json') {
      setMode('data');
    } else {
      setMode('json');
    }
  }
  const onPrompt = async (): Promise<void> => {
    setInput('');
    await doChat.call(node, input);
  }
  const onInvoke = async (): Promise<void> => {
    await doInvoke.call(node, input)
  }
  const onInput = (input: string): void => {
    setInput(input);
  }
  return (
    <GridRow>
      <GridCell options={{ borderTop: true, borderBottom: true, borderLeft: true }}>
        <GridCellIcon height={5} options={options} icon="aios" />
      </GridCell>
      <GridEx size={5} cols0={0} cols1={1} cols2={1}>
        {mode === 'json' ? (
          <GridCellInputMulti options={options} value={input} onChange={onInput} />
        ) : (
          <GridCell>
            <JsonEditor text={text} onChange={setText} />
          </GridCell>
        )}
        <GridCell options={{ borderTop: true, borderBottom: true }}>
          <GridCellButton height={5} options={{ ...options, xMid: true }} icon='send' onClick={onPrompt} />
          {file?.type !== AiosFileType.Code || more === 'less' ? (<></>) : (
            <GridCellButton height={5} options={{ ...options, xMid: true }} icon='invoke' onClick={onInvoke} />
          )}
        </GridCell>
      </GridEx>
      <GridCell options={{ borderTop: true, borderBottom: true, borderRight: true }}>
        <GridCellButton height={5} options={{ ...options, xMid: true }} icon={more} onClick={onMore} />
        {more === 'less' ? (<></>) : (
          <GridCellButton height={5} options={options} icon={mode} onClick={onMode} />
        )}
      </GridCell>
    </GridRow>
  );
};