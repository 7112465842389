import {
  type AiosAsset,
  AiosFileType,
  createAiosAsset,
  createAiosFile,
  type AiosFile,
  is,
  createAiosPath,
  AiosType,
  add,
} from 'aios';
import { type AppNode, createAppNode } from 'app/node/AppNode';
import { AppState } from 'signals/AppState/AppState';
import { getAppNodeData } from 'app';

export function saveAiosAssetText(this: AppNode, name: string, text: string): AppNode {
  // overwite this
  const { dataNode, file } = getAppNodeData(this);
  if (is(dataNode) && is(file)) {
    dataNode.update({ file: { text } });
    return this;
  }

  let path = this.path.path as string;

  // create new
  if (!name.includes(path)) {
    path = `${path}/${name}`;
  }
  const aiosPath = createAiosPath({ path });
  const aiosFile = createAiosFile({
    type: aiosPath.fileType,
    path,
    text,
  });
  return saveAiosAssetFile.call(this, aiosFile);
}

export function saveAiosAssetFile(this: AppNode, file: AiosFile): AppNode {
  const name = file.path as string;
  let path = this.path.path as string;
  if (!name.includes(path)) {
    path = `${path}/${name}`;
  } else {
    path = name;
  }
  const item = createAiosAsset({
    path,
    name,
    file,
  });
  let node;
  if (is(this.nodes)) {
    node = this.nodes?.find((n) => n.path.path === path);
  }
  if (is(node)) {
    node.data = {
      type: AiosType.Asset,
      item,
      loaded: true,
      created: true,
    };
    return node;
  } else {
    node = createAppNode({
      parent: this,
      path: createAiosPath({ path }),
      name,
      data: {
        type: AiosType.Asset,
        item,
        loaded: true,
        created: true,
      },
    });
    this.nodes = add(this.nodes, node);
  }
  return node;
}

export async function createAiosAssetFiles(this: AppNode, files: FileList): Promise<boolean> {
  if (!is(files) || files.length < 1) {
    return true;
  }
  const aiosFiles: AiosFile[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const aiosFile = await fileToAiosFile(file);
    aiosFiles.push(aiosFile);
  }
  if (!is(aiosFiles) || aiosFiles.length < 1) {
    return true;
  }
  const data = this.data;
  if (!is(data)) {
    return true;
  }
  const asset = data.item as AiosAsset;
  const file = asset?.file;
  if (!is(file)) {
    return true;
  }
  if (file.type !== AiosFileType.Folder) {
    const updatedFile = await fileToAiosFile(files[0]);
    this.update({ ...data.item, file: updatedFile });
    AppState.current.refresh();
    return true;
  }
  if (aiosFiles.length > 1) {
    for (const aiosFile of aiosFiles) {
      saveAiosAssetFile.call(this, aiosFile);
    }
    return true;
  }
  data.files = aiosFiles;
  return false;
}

export const fileToAiosFile = async (file: File): Promise<AiosFile> => {
  const aiosFile: AiosFile = {};
  aiosFile.path = file.name;
  aiosFile.size = file.size;
  aiosFile.mime = file.type;
  switch (aiosFile.mime) {
    case 'text/vnd.trolltech.linguist':
      aiosFile.mime = 'application/typescript';
      aiosFile.type = AiosFileType.Code;
      break;
    case 'text/plain':
      aiosFile.type = AiosFileType.Text;
      break;
    case 'application/markdown':
      aiosFile.type = AiosFileType.Markdown;
      break;
    case 'application/typescript':
      aiosFile.type = AiosFileType.Code;
      break;
    case 'application/json':
      aiosFile.type = AiosFileType.Json;
      break;
    case 'application/gltf':
      aiosFile.type = AiosFileType.Model;
      break;
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
      aiosFile.type = AiosFileType.Image;
      break;
    case 'audio/mpeg':
      aiosFile.type = AiosFileType.Audio;
      break;
    case 'video/mp4':
      aiosFile.type = AiosFileType.Video;
      break;
  }
  switch (aiosFile.mime) {
    case 'text/plain':
    case 'application/markdown':
    case 'application/typescript':
    case 'application/json':
    case 'application/gltf':
      aiosFile.text = await loadText(file);
      break;
    default:
      aiosFile.base = await loadData(file);
      break;
  }
  return aiosFile;
};

const loadText = async (file: File): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event?.target?.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file);
  });
};

const loadData = async (file: File): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      let data = reader?.result as string;
      if (is(data)) {
        const index = data.indexOf(',');
        if (index > 0) {
          data = data.substring(index + 1);
        }
      }
      resolve(data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
