import {
  type AiosObject,
  type AiosDataResult,
  type AiosData,
  type AiosProcess,
  add,
  getId,
  is,
  delay,
} from 'aios';
import { type AppDataType } from 'views';
import { type AppNode } from './AppNode';
import { AppState } from 'signals/AppState/AppState';

export interface AppNodeInterface {
  chat: boolean;
  chatHide: boolean;
  chatShow: boolean;
  processing?: AiosDataResult<AiosData>;
  processingHide: boolean;
  processingShow: boolean;
  control?: AppNodeControl;
  histories?: AppNodeHistory[];
  showChat: (show: boolean) => void;
  showProcessing: (result?: AiosDataResult<AiosData>) => void;
  showControl: (control?: AppNodeControl) => void;
  showHistory: (history?: AppNodeHistory) => void;
  switch: (options: AppNodeInterfaceSwitch) => void;
}

export interface AppNodeInterfaceSwitch {
  chat?: boolean;
  result?: AiosDataResult<AiosData>;
  process?: AiosProcess;
  processing?: AiosDataResult<AiosData>;
}

export interface AppNodeCommand {
  name?: string;
  path?: string;
  icon?: string;
  color?: string;
  blink?: boolean;
  disabled?: boolean;
  selected?: boolean;
  onChange?: (this: AppNode, input?: string) => Promise<void>;
  onUpload?: (files: FileList) => Promise<boolean>;
  downloadUrl?: string;
  downloadName?: string;
}

export interface AppNodeControl {
  input?: string;
  form?: AppDataType;
  schema?: AiosObject;
  commands?: AppNodeCommand[];
  doInput?: (input: string) => void;
  doReset?: () => void;
  doSubmit?: () => Promise<void>;
}

export interface AppNodeHistory {
  id?: string;
  to?: string;
  from?: string;
  name?: string;
  date?: number;
  text?: string;
  result?: AiosDataResult<AiosData>;
  process?: AiosProcess;
}

export function createAppNodeInterface(): AppNodeInterface {
  const appNodeInterface: AppNodeInterface = {
    chat: false,
    chatShow: false,
    chatHide: false,
    control: {},
    histories: [],
    processing: undefined,
    processingShow: false,
    processingHide: false,
    showChat: function (this: AppNodeInterface, chat: boolean): void {
      this.chat = chat;
    },
    showControl: function (this: AppNodeInterface, control?: AppNodeControl): void {
      this.control = control;
    },
    showHistory: function (this: AppNodeInterface, history?: AppNodeHistory): void {
      if (!is(history)) {
        return;
      }
      const { result } = history;
      if (is(result)) {
        if (!is(history.id)) {
          history.id = result.id;
          history.result = { ...result };
        }
      }
      if (!is(history.id)) {
        history.id = getId();
      }
      if (!is(history.name)) {
        history.to = 'user';
        history.name = 'you';
      }
      if (!is(history.date)) {
        history.date = Date.now();
      }
      if (is(this.histories)) {
        for (let i = 0; i < this.histories.length; i++) {
          const check = this.histories[i];
          if (check.id === history?.id) {
            this.histories[i] = history;
            return;
          }
        }
      }
      if (!is(this.histories)) {
        this.histories = [history];
      } else {
        let replaced = false;
        if (is(history.id)) {
          for (let i = 0; i < this.histories.length; i++) {
            if (this.histories[i].id === history.id) {
              this.histories[i] = history;
              replaced = true;
              break;
            }
          }
        }
        if (!is(replaced)) {
          this.histories = add(this.histories, history);
        }
      }
    },
    showProcessing: function (this: AppNodeInterface, result?: AiosDataResult<AiosData>): void {
      this.processing = result;
    },
    switch: function (this: AppNodeInterface, options: AppNodeInterfaceSwitch): void {
      if (is(options.chat)) {
        if (is(options.processing) || is(options.process)) {
          this.showHistory({ result: options.processing, process: options.process });
        }
        this.processing = undefined;
        void delay(async () => {
          this.chat = true;
          this.chatShow = true;
          this.chatHide = false;
          AppState.current.refresh();
        }, 250);
        this.chat = true;
        this.chatShow = true;
        this.chatHide = false;
        return;
      }
      if (is(options.processing)) {
        if (!is(this.chat)) {
          this.processing = options.processing;
          this.processingShow = true;
        } else {
          this.chatShow = false;
          this.chatHide = true;
          void delay(async () => {
            this.chat = false;
            this.chatHide = false;
            this.processing = options.processing;
            AppState.current.refresh();
          }, 250);
        }
      }
    },
  };
  return appNodeInterface;
}
