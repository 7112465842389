export enum AiosDataCommandType {
  Undefined = '',
  Auth = 'Auth',
  Load = 'Load',
  Remove = 'Remove',
  Save = 'Save',
  Send = 'Send',
  Chat = 'Chat',
  Read = 'Read',
  Run = 'Run',
  ReadAndRun = 'ReadAndRun',
}
