import { is, isWait, isFail } from 'aios';
import { type AppNode } from '../../AppNode';
import { AppDataForm, AppContentTree } from 'views';
import { AppState } from 'signals/AppState/AppState';
import { doReload, doLoadCancel, getNewN, doReset, doSubmit, doRemoveCancel, doRemoveConfirmCancel, doRemoveConfirm, doRemove } from 'app';

export function appNodeLoadAccountUi(this: AppNode): void {
  switch (this.path.action) {
    case 'create':
    case 'update':
      appNodeLoadAccountUiSave.call(this);
      break;
    case 'delete':
      appNodeLoadAccountUiRemove.call(this);
      break;
    default:
      appNodeLoadAccountUilDefault.call(this);
      break;
  }
}

function appNodeLoadAccountUilDefault(this: AppNode): void {
  if (isFail(this.result.status)) {
    this.interface.switch({ chat: true, processing: this.result });
    this.interface.showControl({
      commands: [
        {
          name: 'reload',
          onChange: doReload,
        },
      ],
    });
    return;
  }
  if (isWait(this.result.status)) {
    this.interface.switch({ processing: this.result });
    this.interface.showControl({
      commands: [
        {
          path: this.path.parent,
          name: 'cancel',
          blink: true,
          onChange: doLoadCancel,
        },
      ],
    });
    return;
  }
  let newN = 'new0';
  if (is(this.nodes)) {
    for (const node of this.nodes) {
      const n = getNewN(node.path.path);
      if (n > -1) {
        newN = `new${n + 1}`;
      }
    }
  }
  this.interface.showControl({
    commands: [
      {
        name: 'create',
        path: `${this.path.path as string}/${newN}?action=create`,
      },
      {
        name: 'update',
        path: `${this.path.path as string}?action=update`,
      },
      {
        name: 'delete',
        path: `${this.path.path as string}?action=delete`,
      },
    ],
  });
}

function appNodeLoadAccountUiSave(this: AppNode): void {
  let cancel;
  if (this.path.action === 'create') {
    cancel = {
      name: 'cancel',
      path: this.path.parent,
      onChange: async () => {
        await AppState.current.root.remove(this.path);
        AppState.current.refresh();
      },
    };
  } else {
    cancel = {
      name: 'cancel',
      path: this.path.path,
    };
  }
  this.interface.showControl({
    form: AppDataForm,
    commands: [
      {
        name: 'reset',
        onChange: doReset,
      },
      {
        name: 'save',
        onChange: doSubmit,
      },
      cancel,
    ],
  });
}

function appNodeLoadAccountUiRemove(this: AppNode): void {
  if (isFail(this.result.status)) {
    this.interface.showControl({
      form: AppContentTree,
      commands: [
        {
          name: 'retry',
          icon: 'reload',
          onChange: doReload,
        },
      ],
    });
    return;
  }
  doRemove.call(this);
  if (this.result.progress !== 0.5) {
    this.interface.showControl({
      form: AppContentTree,
      commands: [
        {
          name: 'cancel',
          path: this.path.path,
          color: 'red',
          blink: true,
          onChange: doRemoveCancel,
        },
      ],
    });
    return;
  }
  this.interface.showControl({
    form: AppContentTree,
    commands: [
      {
        name: 'decline',
        path: this.path.path,
        onChange: doRemoveConfirmCancel,
      },
      {
        name: 'confirm',
        onChange: doRemoveConfirm,
      },
    ],
  });
}
