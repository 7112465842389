import {
  type AiosType,
  type AiosException,
  type AiosResult,
  AiosStatus,
  AiosDataCommandType,
  createAiosDataResult,
  is,
  isFail,
  createAiosData,
} from 'aios';
import { type AppNode } from '../../AppNode';
import { createAiosClientApp, createAppCache } from 'app/util';

export async function appNodeLoadData(this: AppNode): Promise<boolean> {
  if (is(this.data) && is(this.data.loaded)) {
    return true;
  }
  if (!is(this.data)) {
    this.data = {
      type: this.path.aiosType as AiosType,
      item: {},
      loaded: false,
    };
  }
  const thisFull = this.path.full as string;
  const cache = createAppCache();
  try {
    const cached = await cache.load(thisFull);
    if (!is(cached)) {
      this.client = createAiosClientApp();
      if (!is(this.data.type)) {
        this.data.type = this.path.aiosType as AiosType;
      }
      if (!is(this.data.item)) {
        this.data.item = createAiosData({ path: this.path.full });
      }
      this.process = await this.client.send({
        path: this.path,
        command: {
          aiosType: this.data.type,
          commandType: AiosDataCommandType.Load,
          item: this.data.item,
        },
      });
      this.client = undefined;
      if (this.result.status === AiosStatus.FailCancel) {
        return false;
      }
    } else {
      this.process = {
        input: {
          url: '',
          date: new Date().toISOString(),
          path: this.path,
          auth: {},
        },
        output: createAiosDataResult({
          title: this.result.title,
          status: AiosStatus.OkCache,
          item: cached,
        }),
      };
    }
    const result = this.process.output;
    this.result.override(result as AiosResult);
    if (!is(result) || isFail(result?.status)) {
      return false;
    }
    this.data.item = result.item;
    this.data.items = result.items;
    if (is(this.data.item) && !is(this.data.items)) {
      await cache.save(thisFull, this.data.item);
    }
    this.data.loaded = true;
  } catch (exception) {
    this.result.setFail({ exception: exception as AiosException });
  }
  return true;
}
