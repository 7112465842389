import {
  type AiosPath,
  createAiosAccess,
  AiosType,
  is,
  AiosDataCommandType,
  isWait,
  createAiosResult,
  isFail,
  anon,
} from 'aios';
import { type AppNode  } from 'app';
import { appNodeLoadAccessUi } from './appNodeLoadAccessUi';
import { appNodeLoadData } from '../load/appNodeLoadData';

export async function appNodeLoadAccess(
  this: AppNode,
  target: AiosPath,
): Promise<void> {
  const data = this.data;
  if (is(data) && is(data.loaded)) {
    if (is(this.result.status) && !is(target.action)) {
      if (!isWait(this.result.status)) {
        this.result = createAiosResult();
      }
    }
    appNodeLoadAccessUi.call(this);
    return;
  }
  if (isFail(this.result.status)) {
    appNodeLoadAccessUi.call(this);
    return;
  }
  if (this.path.modeId === 'new') {
    appNodeLoadAccessNew.call(this);
  }
  await appNodeLoadAccessData.call(this);
  this.interface.switch({ chat: true, processing: this.result });
  appNodeLoadAccessUi.call(this);
}

async function appNodeLoadAccessData(this: AppNode): Promise<boolean> {
  const data = this.data;
  if (!is(data)) {
    return false;
  }
  if (is(data.loaded)) {
    return true;
  }
  if (is(this.path.action)) {
    return true;
  }
  if (!is(this.path.modeId)) {
    return true;
  }
  if (!is(await appNodeLoadData.call(this))) {
    return false;
  }
  return true;
}

function appNodeLoadAccessNew(this: AppNode): void {
  const parent = this.parent as AppNode;
  const parentPath = parent.parent?.path?.path as string;
  const newItem = createAiosAccess({
    accessType: AiosDataCommandType.Load,
    parent: parentPath,
    actorId: anon
  });
  this.data = {
    type: AiosType.Access,
    item: newItem,
    loaded: true,
  };
  this.result.setOk();
}