import {
  type AiosPath,
  is,
  createAiosResult,
  AiosStatus,
  isChildPath,
} from 'aios';
import { type AppNode } from '../../AppNode';
import { getId, getNewN } from 'app/util';

// todo: is this even being called?
export async function appNodeRemove(
  this: AppNode,
  target?: AiosPath,
): Promise<AppNode | undefined> {
  let node;
  if (!is(target)) {
    target = this.path;
  }
  const thisFull = this.path.full as string;
  const targetFull = target.full as string;
  if (thisFull !== targetFull) {
    node = await appNodeRemoveNodes.call(this, target);
  } else {
    node = await appNodeRemoveNode.call(this);
  }
  return node;
}

async function appNodeRemoveNodes(this: AppNode, target: AiosPath): Promise<AppNode | undefined> {
  if (is(this.nodes)) {
    const targetFull = target.full as string;
    for (let i = 0; i < this.nodes.length; i++) {
      const child = this.nodes[i];
      const childFull = child.path?.full as string;
      if (isChildPath(child.path, target)) {
        if (childFull === targetFull) {
          await appNodeRemoveNode.call(child);
          return this;
        }
        return await appNodeRemoveNodes.call(child, target);
      }
    }
  }
  return undefined;
}

async function appNodeRemoveNode(this: AppNode): Promise<AppNode | undefined> {
  const parent = this.parent as AppNode;
  const thisFull = this?.path.full as string;
  this.result = createAiosResult({
    id: getId(),
    title: `deleting '${thisFull}'`,
    status: AiosStatus.Processing,
  });
  if (getNewN(this.path.name) > -1 && this.path.version !== 'new') {
    // todo: only if not saved
    this.result.setOk();
    // await appNodeRemoveData.call(this);
    // if (isFail(this.result.status) || isWait(this.result.status)) {
    //   return this;
    // }
    parent.interface.showHistory({ result: this.result });
  }
  if (is(parent.nodes)) {
    parent.nodes = parent.nodes.filter((n) => n.path?.full !== thisFull);
  }
  return this;
}
