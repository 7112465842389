import { useEffect, useState } from 'react';
import { is, set } from 'aios';
import { useApp } from 'signals';
import ReactJson, { type InteractionProps } from 'react-json-view';
import './JsonControl.css';

export interface JsonEditorProps {
  text: string;
  onChange: (text: string) => void;
}

export const JsonEditor = ({ text, onChange}: JsonEditorProps) : JSX.Element => {
  const { settings } = useApp();
  const theme = is(settings.dark) ? 'bright' : undefined;
  const [value, setValue] = useState(text);
  useEffect(() => {
    setValue(set(text, ''));
  }, [text]);
  let valid = true;
  let obj = {};
  try {
    if (is(value)) {
      obj = JSON.parse(value);
    }
  }
  catch (e) {
    valid = false;
  }
  if (!is(valid)) {
    return <div>invalid json</div>
  }
  const doChange = (e: InteractionProps): void => {
    const newText = JSON.stringify(e.updated_src) ?? '';
    setValue(newText);
    onChange(newText);
  };
  return (
    <ReactJson
      src={obj}
      theme={theme}
      onAdd={doChange}
      onEdit={doChange}
      onDelete={doChange}
    />
  );
}

export default JsonEditor;
