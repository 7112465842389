import { AiosStatus } from './AiosStatus';
import { type AiosException } from './AiosException';
import { type AiosObject } from './AiosObject';
import { add, is, set } from '../functions';

export interface AiosResult {
  id?: string;
  act?: string;
  title: string;
  status: AiosStatus;
  message: string;
  fields?: Record<string, unknown>;
  results?: Array<Partial<AiosResult>>;
  progress?: number;
  exception?: AiosException;
  time: number;
  size: number;
  cost: number;
  setOk: (options?: Partial<AiosResult>) => AiosResult;
  setFail: (options?: Partial<AiosResult>) => AiosResult;
  setException: (exception?: AiosException) => AiosResult;
  append: (result: AiosResult) => AiosResult;
  override: (result: AiosResult) => AiosResult;
  format: () => AiosObject;
}

export function createAiosResult(options?: Partial<AiosResult>): AiosResult {
  const result: AiosResult = {
    id: set(options?.id, ''),
    act: set(options?.act, undefined),
    title: set(options?.title, ''),
    status: set(options?.status, AiosStatus.None),
    message: set(options?.message, ''),
    fields: set(options?.fields, undefined),
    results: set(options?.results, undefined),
    progress: set(options?.progress, undefined),
    exception: set(options?.exception, undefined),
    time: set(options?.time, 0),
    size: set(options?.size, 0),
    cost: set(options?.cost, 0),
    setOk: set(
      options?.setOk,
      function (this: AiosResult, values?: Partial<AiosResult>): AiosResult {
        this.status = set(values?.status, AiosStatus.Ok);
        this.progress = set(values?.progress, 1);
        this.time = set(values?.time, this.time);
        this.size = set(values?.size, this.size);
        this.cost = set(values?.cost, this.cost);
        return this;
      },
    ),
    setFail: set(
      options?.setFail,
      function (this: AiosResult, values?: Partial<AiosResult>): AiosResult {
        this.status = set(values?.status, AiosStatus.Fail);
        this.fields = set(values?.fields, result.fields);
        this.message = set(values?.message, result.message);
        this.progress = set(values?.progress, undefined);
        this.time = set(options?.time, this.time);
        this.size = set(options?.size, this.size);
        this.cost = set(options?.cost, this.cost);
        return this.setException(values?.exception);
      },
    ),
    setException: set(
      options?.setException,
      function (this: AiosResult, exception?: AiosException): AiosResult {
        if (is(exception)) {
          this.status = AiosStatus.Fail;
          if (is(exception.name)) {
            if (exception.name === 'AbortError') {
              this.status = AiosStatus.FailCancel;
            }
            this.message = `${this.message} ${exception.name}`;
          }
          if (is(exception.message)) {
            this.message = `${this.message} ${exception.message}`;
          }
          if (is(exception.errors)) {
            exception.errors.forEach(
              (er: { message?: string; errorType?: string }) => {
                this.message = `${this.message} ${set(er.message, '')}`;
              },
            );
          }
          this.progress = undefined;
        }
        return this;
      },
    ),
    append: set(
      options?.append,
      function (this: AiosResult, values: Partial<AiosResult>): AiosResult {
        if (is(values.message)) {
          if (!is(this.message)) {
            this.message = values.message;
          } else {
            this.message = `${this.message} ${values.message}`;
          }
        }
        this.results = add(this.results, { ...values });
        this.fields = set(values.fields, result.fields);
        const { time, size, cost } = values;
        if (is(time)) {
          this.time += time;
        }
        if (is(size)) {
          this.size += size;
        }
        if (is(cost)) {
          this.cost += cost;
        }
        return this.setException(values.exception);
      },
    ),
    override: set(
      options?.override,
      function (this: AiosResult, values: AiosResult): AiosResult {
        this.append(values);
        this.status = set(values?.status, result.status);
        return this;
      },
    ),
    format: set(options?.format, function (this: AiosResult) {
      const format: AiosObject = {
        title: `${this.title}...${this.status}`,
      };
      if (is(this.message)) {
        format.message = this.message;
      }
      if (is(this.results)) {
        const results: AiosObject[] = [];
        for (const result of this.results) {
          if (is(result.format)) {
            const child = result.format();
            results.push(child);
          }
        }
        format.results = results;
      }
      return format;
    }),
  };
  return result.setException(options?.exception);
}

export default AiosResult;
