import { appSignal, getClass, useApp } from 'signals';
import { Grid2 } from 'components';
import { AppSideRows } from './AppSideRows';
import { is } from 'aios';

export const AppSide = (): JSX.Element => {
  const { root } = useApp();
  if (!is(appSignal.value.indexMenu)) {
    return <></>;
  }
  return (
    <div className={getClass('app-side')}>
      <Grid2 size='2.5vw' cols={10} >
        <AppSideRows node={root} />
      </Grid2>
    </div>
  );
};
