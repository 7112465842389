import {
  type AiosData,
  type AiosDataCommandType,
  createAiosData,
  set,
} from '../AiosUtil';

export interface AiosAccess extends AiosData {
  accessType?: AiosDataCommandType;
  actorId?: string;
  runable?: boolean;
}

export function createAiosAccess(options?: Partial<AiosAccess>): AiosAccess {
  const base = createAiosData(options);
  const access: AiosAccess = {
    ...base,
    accessType: set(options?.accessType, undefined),
    actorId: set(options?.actorId, undefined),
    runable: set(options?.runable, undefined),
  };
  return access;
}
