import {
  type AiosAccount,
  type AiosAsset,
  type AiosPath,
  type AiosResult,
  type AiosClient,
  type AiosProcess,
  type AiosFile,
  type AiosFileType,
  createAiosPath,
  createAiosResult,
  updateAiosAsset,
  updateAiosAccount,
  set,
  is,
  AiosType,
  createAiosAccount,
  createAiosAsset,
  getId,
  createAiosAccess,
  getAssetData,
} from 'aios';
import { appNodeLoad, appNodeRemove, appNodeSave } from './functions';
import { createAiosAssetSample } from './functions/asset/createAiosAssetSample';
import { type AppNodeInterface, createAppNodeInterface } from './AppNodeInterface';
import { type AppNodeData, type AppNodeDataType } from './AppNodeData';

export interface AppNode {
  parent?: AppNode;
  default?: AppNode;
  id: string;
  path: AiosPath;
  type: AppNodeType;
  name: string;
  data?: AppNodeData;
  nodes?: AppNode[];
  result: AiosResult;
  client?: AiosClient;
  process?: AiosProcess;
  interface: AppNodeInterface;
  find: (target: AiosPath) => AppNode | undefined;
  load: (target?: AiosPath) => Promise<AppNode | undefined>;
  save: (target?: AiosPath) => Promise<AppNode | undefined>;
  remove: (target?: AiosPath) => Promise<AppNode | undefined>;
  update: (update: AppNodeDataType) => void;
  restore: () => void;
}

export enum AppNodeType {
  None = '',
  Root = 'Root',
  Aios = 'Aios',
  User = 'User',
}

export function createAppNode(options?: Partial<AppNode>): AppNode {
  const appNode: AppNode = {
    parent: set(options?.parent, undefined),
    default: set(options?.default, undefined),
    id: set(options?.id, getId()),
    path: set(options?.path, createAiosPath()),
    type: set(options?.type, AppNodeType.None),
    name: set(options?.name, ''),
    data: set(options?.data, undefined),
    nodes: set(options?.nodes, undefined),
    result: set(options?.result, createAiosResult()),
    interface: set(options?.interface, createAppNodeInterface()),
    load: set(options?.load, appNodeLoad),
    save: set(options?.save, appNodeSave),
    remove: set(options?.remove, appNodeRemove),
    find: set(options?.find, function (this: AppNode, target: AiosPath) {
      if (this.path.full === target.full) {
        return this;
      }
      if (is(this.nodes) && is(target.full)) {
        for (let i = 0; i < this.nodes.length; i++) {
          const child = this.nodes[i];
          if (child.path.account === 'aios' && target.account === 'aios') {
            return child;
          }
          const match = child.find(target);
          if (is(match)) {
            return match;
          }
        }
      }
      return undefined;
    }),
    update: set(options?.update, function (this: AppNode, update: AppNodeDataType) {
      const data = this.data;
      if (!is(data)) {
        return;
      }
      if (!is(data.save)) {
        data.save = { ...data.item };
      }
      switch (data.type) {
        case AiosType.Account:
          data.item = updateAiosAccount(data.item as AiosAccount, update);
          break;
        case AiosType.Asset:
          {
            const asset = data.item as AiosAsset;
            const updated = update as AiosAsset;
            const updatedType = updated.file?.type;
            if (is(updatedType) && asset.file?.type !== updatedType) {
              const parentPath = this.parent?.path?.path as string;
              data.item = createAiosAssetSample(updatedType, parentPath);
            } else {
              data.item = updateAiosAsset(data.item as AiosAsset, update);
            }
          }
          break;
        default:
          data.item = update;
          break;
      }
      if (!is(data.changed)) {
        data.changed = true;
      }
    }),
    restore: set(options?.restore, function (this: AppNode) {
      const data = this.data;
      if (!is(data)) {
        return;
      }
      if (is(data.save)) {
        switch (data.type) {
          case AiosType.Account:
            data.item = createAiosAccount(data.save);
            break;
          case AiosType.Asset:
            data.item = createAiosAsset(data.save);
            break;
          case AiosType.Access:
            data.item = createAiosAccess(data.save);
            break;
        }
        data.save = undefined;
      }
      data.changed = false;
    }),
  };
  return appNode;
}

export const getAppNodeData = (node: AppNode): { dataNode?: AppNode, data?: AppNodeData, item?: AppNodeDataType, asset?: AiosAsset, file?: AiosFile, path?: string, type?: AiosFileType, text?: string, base?: string } => {
  let dataNode = node;
  let data = dataNode?.data;
  if (!is(data)) {
    dataNode = node.parent as AppNode;
    data = dataNode?.data;
  }
  const item = data?.item;
  const asset = data?.item as AiosAsset;
  const { file, path, type, text, base } = getAssetData.call(asset); 
  return { dataNode, data, item, asset, file, path, type, text, base };
}