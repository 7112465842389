import {
  type AiosAccount,
  type AiosAuth,
  AiosDataCommandType,
  type AiosDataResult,
  type AiosException,
  AiosStatus,
  AiosType,
  createAiosDataResult,
  createAiosPath,
  is,
  sleep,
  isOk,
  set,
  add,
  createAiosResult,
  isFail,
  isWait,
  delay,
  type AiosProcess,
  type AiosResult,
  createAiosDataCommand,
  createAiosAuth,
} from 'aios';
import { AppState } from 'signals/AppState/AppState';
import { AppNodeType, createAppNode } from 'app/node';
import { createAiosClientApp, createAppCache, getId } from 'app/util';
import { Auth } from 'aws-amplify';
import { Hub, type HubCapsule } from '@aws-amplify/core';
import { appSignal } from 'signals';

const useAws = true;

export class AuthState {
  static current: AuthState;

  constructor() {
    AuthState.current = this;
    if (is(useAws)) {
      Hub.listen('auth', (data: HubCapsule) => {
        if (data.payload.event === 'signIn') {
          void this.signIn();
        }
      });
    }
  }

  waiting = false;
  account?: AiosAccount;
  authResult = createAiosDataResult<AiosAuth>({ id: getId() });
  authAccountResult = createAiosDataResult<AiosAccount>();
  signIn = async (): Promise<void> => {
    if (is(this.account)) {
      return;
    }
    const aios = AppState.current.root.find(createAiosPath({ path: 'aios' }));
    if (!is(aios)) {
      return;
    }
    let auth;
    this.waiting = true;
    if (!is(useAws)) {
      auth = await this.signInLocal();
    } else {
      auth = await this.signInAws();
    }
    if (!is(auth)) {
      aios.result = createAiosResult({
        id: getId(),
        title: 'signing in',
        status: AiosStatus.Processing,
        progress: 0.1,
      });
      aios.interface.showChat(false);
      return;
    }
    if (!isWait(aios.result.status) || aios.result.progress === 0.1) {
      aios.result = createAiosResult({
        id: getId(),
        title: 'signing in',
        status: AiosStatus.Processing,
        progress: 0.2,
      });
      aios.interface.switch({ processing: aios.result });
      AppState.current.refresh();
      void delay(async () => {
        await AuthState.current.signIn();
      }, 100);
      return;
    }
    if (aios.result.progress === 0.2) {
      aios.result.progress = 0.3;
      const process = await this.signInAios(auth);
      const output = process.output as AiosResult;
      aios.result.override(output);
      if (isFail(this.authResult.status)) {
        this.reset();
        aios.interface.showProcessing();
        aios.interface.showHistory({ result: aios.result, process });
        AppState.current.refresh();
        return;
      }
      if (isOk(this.authAccountResult.status)) {
        this.account = this.authAccountResult.item;
        localStorage.setItem('aios_account_id', this.account?.id as string);
        if (this.account?.setting?.theme === 'Light') {
          appSignal.value.light = true;
          AppState.current.setSettings({ dark: true });
        } else {
          appSignal.value.light = false;
          AppState.current.setSettings({ dark: false });
        }
      } else {
        localStorage.removeItem('aios_account_id');
      }
      aios.interface.switch({ chat: true, processing: aios.result, process });
      this.waiting = false;
      AppState.current.refresh();
    }
  };

  signInCancel = (): void => {
    this.waiting = false;
  };

  signInLocal = async (): Promise<AiosAuth | null> => {
    await sleep(1);
    const auth = {
      id: '',
      key: '',
      email: '',
      token: '',
    };
    return auth;
  };

  signInAws = async (): Promise<AiosAuth | null> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (is(user)) {
        const id = user?.attributes?.sub as string;
        const authSession = await Auth.currentSession();
        const authorization = authSession.getAccessToken().getJwtToken();
        const auth = createAiosAuth({
          id: `aws${id}`,
          email: user.attributes.email,
          headers: {
            authorization: `Bearer ${authorization}`,
          }
        });
        return auth;
      }
    } catch {}
    return null;
  };

  signInAios = async (auth: AiosAuth): Promise<AiosProcess> => {
    const client = createAiosClientApp({ auth });
    const path = createAiosPath();
    const cacheId = set(localStorage.getItem('aios_cache_id'), getId());
    const cacheDate = localStorage.getItem('aios_cache_date');
    const command = createAiosDataCommand({
      aiosType: AiosType.Auth,
      commandType: AiosDataCommandType.Auth,
      item: { ...auth, cacheId },
    });
    const process = await client.send({
      path,
      command,
    });
    const authResult = process.output;
    const updatedAuth = authResult?.item as AiosAuth;
    if (is(updatedAuth) && updatedAuth.lastSignIn !== cacheDate) {
      const cache = createAppCache();
      await cache.clear();
      localStorage.setItem('aios_cache_id', cacheId);
      localStorage.setItem('aios_cache_date', updatedAuth.lastSignIn as string);
    }
    const accountResult = authResult?.results?.[2] as AiosDataResult<AiosAccount>;
    if (is(authResult) && is(accountResult)) {
      this.authResult.override(authResult);
      this.authAccountResult.override(accountResult);
      const path = createAiosPath({
        path: set(this.authAccountResult.item?.path, this.authAccountResult.item?.id),
      });
      const userNode = createAppNode({
        id: this.authAccountResult.item?.id,
        path,
        type: AppNodeType.User,
        name: path.path,
        data: {
          type: AiosType.Account,
          item: this.authAccountResult.item,
        },
      });
      AppState.current.root.nodes = add(AppState.current.root.nodes, userNode);
    } else {
      this.authResult.setFail({ message: 'authorization failed' });
    }
    return process;
  };

  signOut = async (): Promise<boolean> => {
    this.waiting = true;
    this.authResult = createAiosDataResult({
      title: 'signing out',
      status: AiosStatus.Processing,
    });
    try {
      if (!is(useAws)) {
        await this.signOutLocal();
      } else {
        await this.signOutAws();
      }
      this.reset();
      this.waiting = false;
      return true;
    } catch (exception) {
      this.authResult.setFail({ exception: exception as AiosException });
      this.waiting = false;
    }
    const aios = AppState.current.root.find(createAiosPath({ path: 'aios' }));
    if (is(aios)) {
      aios.result.setOk();
      aios.interface.showHistory({ result: aios.result });
      AppState.current.root.nodes = [aios];
      AppState.current.refresh();
    }
    this.waiting = false;
    return false;
  };

  signOutLocal = async (): Promise<void> => {
    await sleep(1);
  };

  signOutAws = async (): Promise<void> => {
    await Auth.signOut({ global: true });
  };

  reset = (): void => {
    this.waiting = false;
    this.account = undefined;
    localStorage.clear();
    const cache = createAppCache();
    void cache.clear();
  };

  refresh = (auth?: Partial<AuthState>): void => {
    console.log('AuthState refresh not implemented');
    console.log(auth);
  };
}
