import {
  AiosType,
  delay,
  is,
  isFail,
  isOk,
  isWait,
} from 'aios';
import { type AppNode } from '../../AppNode';
import { AppState } from 'signals/AppState/AppState';
import { appNodeRemoveLast } from './appNodeRemoveLast';
import { AuthState } from 'signals/AppState/AuthState';

export async function appNodeRemoveNext(this: AppNode): Promise<void> {
  if (isFail(this.result.status)) {
    return;
  }
  if (!is(this.result.progress)) {
    return;
  }
  const remove = this.parent;
  if (!is(remove)) {
    return;
  }
  await appNodeRemoveLast.call(remove);
  if (isWait(remove.result.status)) {
    if (this.result.progress === 0.8) {
      this.result.progress = 0.6;
    } else {
      this.result.progress = this.result.progress + 0.1;
    }
    void delay(async () => {
      await appNodeRemoveNext.call(this);
    });
    AppState.current.refresh();
    return;
  }
  this.result.override(remove.result);
  // todo: data got removed in RemoveLast
  // this.result = createAiosResult({
  //   id: this.result.id,
  //   title: `deleting data '${this.path.path as string}'`,
  //   status: AiosStatus.Processing,
  //   progress: 0.9,
  // });
  // await appNodeRemoveData.call(remove);
  // this.result.override(remove.result);
  if (isOk(remove.result.status)) {
    if (remove.path.aiosType === AiosType.Account) {
      void delay(async () => {
        if (!(await AuthState.current.signOut())) {
          this.result.setFail();
          this.interface.showHistory({ result: this.result });
          return;
        }
        window.location.href = '/aios';
        this.result.setOk();
      });
      return;
    }
    const parent = remove.parent as AppNode;
    if (is(parent)) {
      parent.interface.showHistory({ result: remove.result });
      await AppState.current.root.remove(remove.path);
      AppState.current.goTo(parent.path);
    }
    return;
  }
  AppState.current.refresh();
}
