import is from './is';

export function getDateKey(date?: Date): string {
  if (!is(date)) {
    date = new Date();
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const monthFormatted = `${month < 10 ? '0' : ''}${month}`;
  const dayFormatted = `${day < 10 ? '0' : ''}${day}`;
  const hoursFormatted = `${hours < 10 ? '0' : ''}${hours}`;
  const minutesFormatted = `${minutes < 10 ? '0' : ''}${minutes}`;
  const secondsFormatted = `${seconds < 10 ? '0' : ''}${seconds}`;
  return `${year}${monthFormatted}${dayFormatted}${hoursFormatted}${minutesFormatted}${secondsFormatted}`;
}

export default getDateKey;
