import { is } from 'aios';
import { type AppNode } from 'app/node/AppNode';
import { AppState } from 'signals/AppState/AppState';

export async function doAssetCreateCancel(this: AppNode): Promise<void> {
  const parent = this.parent;
  if (!is(parent)) {
    return;
  }
  await AppState.current.root.remove(parent?.path);
  AppState.current.refresh();
}
