import { useApp, useRoute, getClass } from 'signals';
import { is } from 'aios';
import { Grid2 } from 'components';

import { AppContentHistoryEx } from 'views/AppContent/AppContentHistoryEx';
import { AppContentProcessing } from 'views/AppContent/AppContentProcessing';

export const AppChat = (): JSX.Element => {
  const { root } = useApp();
  const { path, goTo } = useRoute();
  if (path.path === '' || path.path === '/') {
    return (
      <div>AppChat no path</div>
    );
  }
  let node = root.find(path);
  if (!is(node)) {
    return (
      <div>AppChat no node</div>
    );
  }
  if (!is(path.action)) {
    if (is(node.default)) {
      node = node.default;
    }
  }
  const { chat, chatShow, chatHide, processing: result, processingShow: resultShow, processingHide: resultHide, histories } = node.interface;
  return (
    <div className={getClass('app-chat')}>
      <div className='app-chat-container'>
        <div className='app-chat-controls'>
          <Grid2 size='2.5vw' cols={10}> 
            {
              is(histories) ?
                histories.map((m, i) => (
                  <AppContentHistoryEx key={`app-chat-history-${i}`} history={m} id={`${histories.length - i - 1}`} />
                )) :
                (<></>)
            }
            {
              is(result) ?
                (<AppContentProcessing node={node} show={resultShow} hide={resultHide} />) :
                (<></>)
            }
          </Grid2>
        </div>
      </div>
    </div>
  );
};
