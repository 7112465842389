import {
  type AiosException,
  type AiosFile,
  createAiosPath,
  AiosType,
  is,
  AiosDataCommandType,
  isOk,
  createAiosDataCommand,
} from 'aios';
import { type AppNode } from '../../AppNode';
import { createAiosClientApp, createAppCache } from 'app/util';

const cache = createAppCache();

export async function appNodeSaveData(this: AppNode): Promise<AppNode | undefined> {
  const path = this.path;
  const data = this.data;
  if (!is(data)) {
    return this;
  }
  const command = createAiosDataCommand({
    aiosType: path.aiosType as AiosType,
    commandType: AiosDataCommandType.Save,
    item: data.item,
  });
  try {
    this.client = createAiosClientApp();
    const process = await this.client.send({ path, command });
    const result = process.output;
    if (is(result)) {
      this.result.override(result);
      if (isOk(this.result.status)) {
        if (is(result.item)) {
          const { name, file } = data.item as { name: string; file: AiosFile };
          data.item = { ...result.item, file };
          this.id = result.item.id as string;
          this.name = is(name) ? name : (result.item.path as string);
          this.path = createAiosPath({
            aiosType: AiosType.Asset,
            path: result.item.path as string,
          });
          await cache.save(this.path.full as string, data.item);
        }
        data.loaded = true;
        data.changed = false;
      }
    }
  } catch (exception) {
    this.result.setFail({ exception: exception as AiosException });
  }
  return this;
}
