import {
  type AiosAsset,
  createAiosPath,
  is,
  isFile,
  AiosAssetSchema,
  isWait,
  isFail,
  isFolder,
  toDataUrl,
} from 'aios';
import { getAppNodeData, type AppNode } from '../../AppNode';
import { AppContentAsset, AppDataList, AppContentTree } from 'views';
import { doRemove, doRemoveCancel, doRemoveConfirm, doRemoveConfirmCancel } from '../remove';
import { doSaveCancel, doSave, doSaveConfirm, doSubmit, getNewN, doChatCancel } from 'app';
import { type AppNodeControl } from 'app/node/AppNodeInterface';
import { doInvokeCancel } from '../run/doInvoke';
import { createAiosAssetFiles } from './createAiosAsset';
import { doReload, doLoadCancel } from '../load/doLoad';
import { doAssetCreateCancel } from './doAssetCreateCancel';

export function appNodeLoadAssetUi(this: AppNode): void {
  if (is(this.data?.created)) {
    appNodeLoadAssetUiSave.call(this);
    return;
  }
  switch (this.path.action) {
    case 'create':
    case 'update':
      appNodeLoadAssetUiSave.call(this);
      break;
    case 'delete':
      appNodeLoadAssetUiRemove.call(this);
      break;
    case 'savenx':
      appNodeLoadAssetUiSaveNext.call(this);
      break;
    default:
      appNodeLoadAssetUiDefault.call(this);
      break;
  }
}

function appNodeLoadAssetUiDefault(this: AppNode): void {
  if (isFail(this.result.status)) {
    this.interface.switch({ chat: true, processing: this.result });
    this.interface.showControl({
      commands: [
        {
          name: 'reload',
          onChange: doReload,
        },
      ],
    });
    return;
  }
  if (isWait(this.result.status)) {
    switch (this.result.act) {
      case 'chat':
        this.interface.showControl({
          commands: [
            {
              name: 'cancel',
              onChange: doChatCancel,
              blink: true,
            },
          ],
        });
        break;
      case 'run':
        this.interface.showControl({
          commands: [
            {
              name: 'cancel',
              onChange: doInvokeCancel,
              blink: true,
            },
          ],
        });
        break;
      default:
        this.interface.showControl({
          commands: [
            {
              path: this.path.parent,
              name: 'cancel',
              blink: true,
              onChange: doLoadCancel,
            },
          ],
        });
        break;
    }
    return;
  }
  const control: AppNodeControl = {};
  let newN = 'new0';
  if (is(this.nodes)) {
    for (const node of this.nodes) {
      const n = getNewN(node.path.path);
      if (n > -1) {
        newN = `new${n + 1}`;
      }
    }
  }
  if (!is(this.path.version)) {
    const { type } = getAppNodeData(this);
    control.form = AppContentAsset;
    control.commands = [];
    if (isFolder(type)) {
      control.commands.push({
        name: 'create',
        path: `${this.path.path as string}/${newN}?action=create`,
      });
      control.commands.push({
        name: 'update',
        path: `${this.path.path as string}?action=update`,
      });
      control.commands.push({
        name: 'delete',
        path: `${this.path.path as string}?action=delete`,
      });
      control.commands.push({
        name: 'upload',
        path: `${this.path.path as string}/${newN}?action=create`,
        onUpload: async (files: FileList) => {
          return await createAiosAssetFiles.call(this, files);
        },
      });
      // control.commands.push({
      //   name: 'save next',
      //   icon: 'savenx',
      //   path: `${this.path.path as string}?action=savenx`,
      // });
    } else {
      control.commands.push({
        name: 'update',
        path: `${this.path.path as string}?action=update`,
      });
      control.commands.push({
        name: 'delete',
        path: `${this.path.path as string}?action=delete`,
      });
      control.commands.push({
        name: 'version',
        path: createAiosPath({
          path: `${this.path.account as string}@all/${this.path.assetPath as string}`,
        }).full,
      });
    }
    if (isFile(this.path.fileType)) {
      const asset = this.data?.item as AiosAsset;
      if (is(asset)) {
        control.commands.push({
          name: 'download',
          downloadUrl: is(asset.file) ? toDataUrl(asset.file) : undefined,
          downloadName: asset.name,
        });
      }
    }
    this.interface.showControl(control);
    return;
  }
  if (this.path.version === 'all') {
    control.form = AppDataList;
    control.commands = [
      {
        name: 'create',
        path: createAiosPath({
          path: this.path.path,
          version: 'new',
          action: 'create',
        }).full,
      },
      {
        name: 'close',
        path: this.parent?.path?.path,
      },
    ];
    this.interface.showControl(control);
    return;
  }
  if (this.path.version === 'new') {
    appNodeLoadAssetUiSave.call(this);
    return;
  }
  control.form = AppContentAsset;
  control.commands = [
    {
      name: 'delete',
      path: `${this.path.path as string}?action=delete`,
    },
    {
      name: 'access',
      path: `${this.path.path as string}?mode=access`,
    },
    {
      name: 'action',
      path: `${this.path.path as string}?mode=action`,
    },
    {
      name: 'version',
      path: `${this.path.account as string}@all/${this.path.assetPath as string}`,
    },    
  ];
  this.interface.showControl(control);
}

function appNodeLoadAssetUiSave(this: AppNode): void {
  if (isFail(this.result.status)) {
    this.interface.showControl({
      form: AppContentAsset,
      commands: [
        {
          name: 'ok',
          onChange: doReload,
        },
      ],
    });
    return;
  }
  if (isWait(this.result.status)) {
    this.interface.showControl({
      form: AppContentAsset,
      schema: AiosAssetSchema,
      commands: [
        {
          path: this.path.parent,
          name: 'cancel',
          blink: true,
          onChange: doLoadCancel,
        },
      ],
    });
    return;
  }
  const control: AppNodeControl = {
    form: AppContentAsset,
    schema: AiosAssetSchema,
    commands: [
      {
        name: 'save',
        onChange: doSubmit,
      },
      // {
      //   name: 'load',
      //   onChange: doReload,
      // },
    ],
  };
  if (this.path.action === 'create') {
    control.commands?.push({
      name: 'cancel',
      path: this.path.parent,
      onChange: doAssetCreateCancel,
    });
  } else {
    control.commands?.push({
      name: 'cancel',
      path: this.path.path,
    });
  }
  this.interface.showControl(control);
  // this.interface.switch({ chat: true });
}

function appNodeLoadAssetUiRemove(this: AppNode): void {
  if (isFail(this.result.status)) {
    this.interface.showControl({
      form: AppContentTree,
      commands: [
        {
          name: 'retry',
          icon: 'reload',
          onChange: doReload,
        },
      ],
    });
    return;
  }
  doRemove.call(this);
  if (this.result.progress !== 0.5) {
    this.interface.showControl({
      form: AppContentTree,
      commands: [
        {
          name: 'cancel',
          path: this.path.path,
          color: 'red',
          blink: true,
          onChange: doRemoveCancel,
        },
      ],
    });
    return;
  }
  this.interface.showControl({
    form: AppContentTree,
    commands: [
      {
        name: 'decline',
        path: this.path.path,
        onChange: doRemoveConfirmCancel,
      },
      {
        name: 'confirm',
        onChange: doRemoveConfirm,
      },
    ],
  });
}

function appNodeLoadAssetUiSaveNext(this: AppNode): void {
  if (isFail(this.result.status)) {
    this.interface.showControl({
      form: AppContentTree,
      commands: [
        {
          name: 'retry',
          icon: 'reload',
          onChange: doReload,
        },
      ],
    });
    return;
  }
  doSave.call(this);
  if (!is(this.result.progress) || this.result.progress === 0.1) {
    this.interface.showControl({
      form: AppContentTree,
      commands: [
        {
          name: 'decline',
          path: this.path.path,
        },
        {
          name: 'confirm',
          onChange: doSaveConfirm,
        },
      ],
    });
  } else {
    this.interface.showControl({
      form: AppContentTree,
      commands: [
        {
          name: 'cancel',
          color: 'red',
          blink: true,
          onChange: doSaveCancel,
        },
      ],
    });
  }
}
