import { type AiosPath, is } from 'aios';
import { type AppNode } from '../../AppNode';
import { appNodeLoadData } from '../load/appNodeLoadData';
import { AppDataList } from 'views';

export async function appNodeLoadAction(
  this: AppNode,
  target: AiosPath,
): Promise<AppNode | undefined> {
  if (!(await appNodeLoadActionData.call(this))) {
    return this;
  }
  const thisFull = this.path.full as string;
  const targetFull = target.full as string;
  if (thisFull !== targetFull) {
    return this;
  }
  appNodeLoadActionUi.call(this);
  this.result.setOk();
  return this;
}

async function appNodeLoadActionData(this: AppNode): Promise<boolean> {
  const data = this.data;
  if (!is(data)) {
    return false;
  }
  if (is(data.loaded)) {
    return true;
  }
  if (is(this.path.action)) {
    return true;
  }
  if (!is(await appNodeLoadData.call(this))) {
    return false;
  }
  return true;
}

function appNodeLoadActionUi(this: AppNode): void {
  this.interface.showControl({
    form: AppDataList,
    commands: [
      {
        name: 'close',
        path: this.path.parent,
      },
    ],
  });
}
